import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Chakra Import
import {
	Box,
	Flex,
	Text,
	Badge,
	Icon,
	Avatar,
	Switch,
	Spacer,
	Heading,
	Spinner,
	useToast,
	useDisclosure} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiOutlinePlus,
	AiOutlineHolder,
} from "react-icons/ai";

// Api Call Imports
import {
	fetchAccountDetails,
	manageAccountStatus,
} from "../../../services/businessServices";

// Component Imports
import AddUpdateAccountList from "./AddUpdateAccountList";
import {
	useBrandBg, useBusinessAccountTypeCardIcon,
	useBusinessAccountTypeSwitch,
	useNoResultFoundText,
	useCardTextHover,
	useBusinessAccountTypeBg,
	useBusinessAccountTypeText,
	useCardHoverBg,
	usePillButtonTextColor,
	usePillButtonBg,
	usePillButtonTextColorHover,
	usePillButtonBgSelected,
	usePillButtonBgHover,
	useListItemPillHoverBg
} from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import Pagination from "../../../components/pagination/Pagination";
import { INIT_CURRENT_SELECTION, INIT_PAGE, INIT_RECORDS_PER_PAGE } from "./constants";

function AccountsListing() {
	const toast = useToast();
	const brandBg = useBrandBg();
	const businessTypeBg = useBusinessAccountTypeBg()
	const cardIconColor = useBusinessAccountTypeCardIcon()
	const accountTypeTextColor = useBusinessAccountTypeText()
	const accountCardHoverBg = useCardHoverBg()
	const cardHoverTextColor = useCardTextHover()
	const localListItemPillHoverBg =  useListItemPillHoverBg()
	const switchColorScheme = useBusinessAccountTypeSwitch()
	const noBusinessAccountFoundText = useNoResultFoundText()
	const navigate = useNavigate();
	const [loading, isLoading] = useState(true);
	const [activeCount, setActiveCount] = useState(0)
	const [accountList, setAccountList] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedAccountData, setSelectedAccountData] = useState({})
	const [currentSelection, setCurrentSelection] = useState(INIT_CURRENT_SELECTION);
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
	const [currentPage, setCurrentPage] = useState(INIT_PAGE);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(INIT_RECORDS_PER_PAGE)

	const pillButtonBg = usePillButtonBg()
	const buttonGroupTextColor = usePillButtonTextColor();
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonBgSelected = usePillButtonBgSelected()
	const pillButtonTextColorHover = usePillButtonTextColorHover()


	//   Todo:Fetch all account details
	useEffect(() => {
		fetchAccountList(currentSelection);
	}, [currentPage, recordsPerPage]);

	const fetchAccountList = (local_status) => {
		isLoading(true);
		let paramObj = {
			key: local_status,
			type: 'full',
			records_per_page: recordsPerPage,
			page_id: currentPage,
		};
		fetchAccountDetails(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.total_records) {
					isLoading(false);
					setAccountList(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.total_pages)
				} else {
					isLoading(false);
					setAccountList([]);
					setTotalPages(0)
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountList([]);
			});
	};

	// Todo:Update Account Status
	const updateAccountStatus = (accountId, status) => {
		if (accountId != null) {
			let objBody = {
				link_account_uuid: accountId,
			};
			manageAccountStatus(objBody)
				.then(res => {
					if (res?.result) {
						fetchAccountList(currentSelection);
						toast(toastFunctionToaster(res.message, "success"));
					} else {

						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
		}
	};

	// Todo:Render Account Details
	const renderAccountDetails =
		accountList !== null && accountList?.length > 0 ? (
			accountList?.map((item, index) => (
				<Flex
					w={"100%"}
					key={index}
					rounded={"md"}
					align={"center"}
					p={1}
					mt={1}
					borderWidth={"0.05px"}
					borderColor={item?.is_active ? "green.300" : "red.200"}>
					<Flex
						role="group"
						onClick={() => {
							navigate("/business/accountmanagement/manage", {
								state: {
									accountUuid: item?.link_account_uuid,
								},
							});
						}}
						_hover={{ cursor: "pointer", bg: accountCardHoverBg, color: cardHoverTextColor }}
						rounded={"md"}
						px={1}
						w={"100%"}
						align={"center"}>
						<Box minW={"60px"}>
							<Avatar src={item?.link_account_avatar?.length ? item?.link_account_avatar : null} />
						</Box>
						<Flex minW={"200px"} maxW={'200px'} p={1} direction={"column"} flexWrap={'wrap'}>
							<Badge _groupHover={{ color: cardHoverTextColor, bg:localListItemPillHoverBg }} bg={businessTypeBg} color={accountTypeTextColor}>{item?.link_account_type}</Badge>
						</Flex>
						<Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
							<Text fontWeight={'bold'}>
								{item?.link_account_title}
							</Text>
							<Text w="100%">{item?.link_account_name}</Text>
							<Text w="100%" fontSize={'sm'}>{item?.link_account_details}</Text>
						</Flex>
						<Spacer />
					</Flex>
					<Flex
						px={2}
						alignItems={"center"}
						borderLeftWidth={"1px"}
						borderLeftColor={"gray.400"}
						minW={"100px"}
						align={"end"}>
						<Switch
							onChange={() => {
								updateAccountStatus(item?.link_account_uuid);
							}}
							isChecked={item?.is_active ? true : false}
							colorScheme={switchColorScheme}
						/>
						<Icon
							as={AiOutlineHolder}
							ml={1}
							p={1}
							bg={"gray.200"}
							rounded={"md"}
							fontSize={"24px"}
							cursor={"pointer"}
							onClick={() => {
								onOpen();
								setSelectedAccountData(item)
							}}
							color={cardIconColor}
						/>
					</Flex>
				</Flex>
			))
		) : (
			<Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
				<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={noBusinessAccountFoundText}>
					You do not have any account added.
				</Heading>
			</Flex>
		);

	// Todo:Return Active Count
	useEffect(() => {
		if (accountList?.length) {
			const returnActiveCount = accountList?.filter((data) => { return data?.is_active === true })
			setActiveCount(returnActiveCount?.length)
		}
	}, [accountList])

	// Todo:Close Drawer
	const handleClose = () => {
		onClose();
		setSelectedAccountData({})
	}

	const fetchAccountData = (data) => {
		if (data) {
			onClose();
			fetchAccountList(currentSelection);
		}
	}

	return (
		<>
			<Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
				<Card p={1} mx={3} alignItems="center" rounded={'md'}>
					<Flex w={'100%'} direction={'row'} maxH={'60px'}>
						<Text fontWeight={"500"} fontSize={"18px"}>
							Business Accounts {accountList?.length > 0 ? accountList?.length : 0} ({currentSelection})
						</Text>
						<Spacer />
						<Flex minW={'480px'} maxW={'480px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
							<Text
								minW={'70px'} maxW={'70px'}
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									setCurrentSelection('active');
									fetchAccountList('active');
								}}
								p={2} roundedLeft={'md'}
								color={currentSelection === 'active' ? 'white' : buttonGroupTextColor}
								bg={currentSelection === 'active' ? pillButtonBgSelected : pillButtonBg}>
								Active
							</Text>
							<Text
								minW={'80px'} maxW={'80px'}
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									setCurrentSelection('closed');
									fetchAccountList('closed');
								}}
								color={currentSelection === 'closed' ? 'white' : buttonGroupTextColor}
								p={2}
								bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
								Closed
							</Text>
							<Text
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									fetchAccountList(currentSelection);
								}}
								color={currentSelection === 'refresh' ? 'white' : buttonGroupTextColor}
								p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
								pr={5}>Refresh
							</Text>
							<Text
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									onOpen();
								}}
								roundedRight={'md'}
								color={currentSelection === 'new' ? 'white' : buttonGroupTextColor}
								p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
								pr={5}>
								<Icon pt={1} as={AiOutlinePlus} mr={1} />
								Add New Account
							</Text>
						</Flex>
					</Flex>
				</Card>
				<Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1} >
					<Flex h="calc(100vh - 250px)" overflowX="auto">
						<Box
							w={!configurationCollapseFlag ? "95%" : "85%"}
							bg={brandBg}
							p={1}
							borderRadius={"10px"}
							overflow="hidden">
							<Flex w={"100%"} direction={"column"}>
								{loading ? (
									<Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
										<Spinner size={"xl"} />
									</Flex>
								) : (
									<Flex w={"100%"} direction={"column"}>
										<Flex
											direction={"column"}
											w={"100%"}
											h="calc(100vh - 200px)"
											overflow="auto"
											borderTopWidth="0px"
											className="custom-scrollbar">
											{renderAccountDetails}
										</Flex>
									</Flex>
								)}
							</Flex>
						</Box>
						{configurationCollapseFlag ? (
							<Flex w="15%">
								<Box
									w="100%"
									position="relative"
									p="3"
									borderRadius="15px"
									bg={brandBg}
									ml="2">
									<Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
										<AiFillRightCircle
											cursor="pointer"
											size="20px"
											onClick={() => {
												setConfiguationCollapseFlag(!configurationCollapseFlag);
											}}
										/>

									</Box>
									<Box w="100%" h="95%">
										<Text fontWeight={'600'} >Account Stats</Text>
										<Card mt='10px' borderRadius='10px'>
											<Text textAlign={'center'} fontWeight={'600'} color="green.500">Active Accounts</Text>
											<Text textAlign={'center'} fontWeight={'600'} fontSize={'20px'}>{activeCount}</Text>
										</Card>
										<Card mt='10px' borderRadius='10px'>
											<Text textAlign={'center'} fontWeight={'600'} color="red.500">Disable Accounts</Text>
											<Text textAlign={'center'} fontWeight={'600'} fontSize={'20px'}>{accountList?.length - activeCount}</Text>
										</Card>
									</Box>
								</Box>
							</Flex>
						) : (
							<Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
								<Box w={"100%"} h="100%">
									<AiFillLeftCircle
										cursor="pointer"
										onClick={() => {
											setConfiguationCollapseFlag(!configurationCollapseFlag);
										}}
										size="20px"
									/>
									<Text
										fontSize="16px"
										mt="2"
										fontWeight='bold'
										css={{
											writingMode: "vertical-lr",
											textOrientation: "mixed",
										}}>
										Account Stats
									</Text>
								</Box>
							</Box>
						)}
					</Flex>
				</Card>
				{accountList.length ? (<Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage}/>) : null}
			</Box>
			<AddUpdateAccountList isOpen={isOpen} close={handleClose} callList={fetchAccountData} accountData={selectedAccountData} />
		</>
	);
}

export default AccountsListing;
