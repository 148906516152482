//used in LocationCallHistory

export const INIT_PAGE = 1
export const INIT_RECORDS_PER_PAGE = 2

export const LINGO_LINKS_FILTER = {
    ACTIVE:"active",
    CLOSED:"closed"
}

export const CALL_LIST_TYPE = {
    ONLY_CALLS:"callOnly",
    CALLS_WITH_SESSION:"callWithSession"
}