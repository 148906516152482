import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Box,
	Button,
	Input,
	Heading,
	Spinner,
	Flex,
	Text,
	Icon,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Select,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
} from "react-icons/ai";

// Component Imports
import { formatTime, formatSeconds } from "../../../utils/TimeFunctions";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
	useBrandBg,
	useGreenBg,
	useRedBg,
	useListItemBgColor,
	useListItemTextColor,
} from "../../../theme/globalColorTheme";
import {
	fetchLocationsAndSubs,
	fetchAccountEvents,
	fetchSubscriberLinks,
	fetchSubscriberLinksCallHistory,
} from "../../../services/accountServices";
import Pagination from "../../../components/pagination/Pagination";
import { ChevronDownIcon } from "@chakra-ui/icons";

const LocationCallHistory = props => {
	const redBg = useRedBg();
	const greenBg = useGreenBg();
	const [loading, isLoading] = useState(false);
	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
	const [eventValue, setEventValue] = useState("");
	const [subsId, setSubsId] = useState(null);
	const [locationList, setLocationList] = useState([]);
	const [locationData, setLocationData] = useState({});
	const [linkLoader, setLinkLoader] = useState(false);
	const [linkData, setLinkData] = useState([]);
	const [linkUuid, setLinkUuId] = useState("");
	const [callHistoryList, setCallHistoryList] = useState([]);
	const [callHistoryLoader, setCallHistoryLoader] = useState(false);
	const [cloneEventList, setCloneEventList] = useState([]);
	const [linkDataFlag, setLinkdataFlag] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [sort, setSort] = useState('')

	useEffect(() => {
		const data = linkData.filter(item => item.uuid === linkUuid)
		if (data.length) {
			collectCallHistory(data[0])
		}
	}, [currentPage, recordsPerPage, linkData]);

	useEffect(() => {
		setCurrentPage(1)
	}, [recordsPerPage])

	useEffect(() => {
		collectLocationWithSubs(null)
	}, [])

	// Todo:Fetch Location Details
	const collectLocationWithSubs = currentSelection => {
		isLoading(true);
		let paramObj = {
			link_account_uuid: null,
			key: currentSelection,
			records_per_page: 0,
			page_id: 0,
		};
		fetchLocationsAndSubs(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.locations) {
					isLoading(false);
					setLocationList(res?.data[0]?.locations);
				} else {
					isLoading(false);
					setLocationList([]);
				}
			})
			.catch(err => {
				isLoading(false);
				setLocationList([]);
			});
	};

	// Todo:Fetch Lingo Link Details
	const collectLinkData = (locationData, subscriberData) => {
		setLinkLoader(true);
		let obj = {
			account_uuid: locationData?.link_account_uuid,
			location_uuid: locationData?.link_location_uuid,
			subscriber_uuid: subscriberData?.sub_uuid,
			link_status: "active",
			duration: "all",
		};
		fetchSubscriberLinks(obj)
			.then(res => {
				setLinkLoader(false);
				if (res?.data?.[0]?.records?.length) {
					setLinkData(res?.data?.[0]?.records);
					setCallHistoryList([]);
					setLinkUuId("");
				} else {
					setLinkData([]);
					setCallHistoryList([]);
				}
			})
			.catch(err => {
				if (err) {
					setLinkLoader(false);
					setLinkData([]);
					setCallHistoryList([]);
					setLocationData({});
					setLinkUuId("");
				}
			});
	};

	// Todo : Collect Call History Based on Link Id
	const collectCallHistory = data => {
		setCallHistoryLoader(true);
		let obj = {
			account_uuid: locationData?.link_account_uuid,
			location_uuid: locationData?.link_location_uuid,
			linkUuid: data?.uuid,
			linkKey: data?.key,
			page_id: currentPage,
			limit: recordsPerPage
		};
		fetchSubscriberLinksCallHistory(obj)
			.then(res => {
				setCallHistoryLoader(false);
				if (res?.data?.[0]?.sessions?.length) {
					setCallHistoryList(res?.data?.[0]?.sessions);
					setTotalPages(res?.data[0]?.total_pages)
				} else {
					setCallHistoryList([]);
					setTotalPages(0)
				}
			})
			.catch(err => {
				setCallHistoryLoader(false);
			});
	};

	function sortLocations() {
		if (locationList && locationList.length > 1 && sort !== '') {

			const tempLocations = [...locationList].sort((a, b) => {
				if (sort === 'asc') {
					if (a.is_sub_location !== b.is_sub_location) {
						return a.is_sub_location - b.is_sub_location;
					}
					return a.link_location_title.localeCompare(b.link_location_title);
				} else if (sort === 'desc') {
					if (a.is_sub_location !== b.is_sub_location) {
						return a.is_sub_location - b.is_sub_location;
					}
					return b.link_location_title.localeCompare(a.link_location_title);
				}
				return 0;
			})

			setLocationList(tempLocations)
		}
	}

	useEffect(() => {
		sortLocations()
	}, [sort])

	// Todo:Render Location List
	const renderLocationList = locationList?.map((data, idx) => {
		return (
			<Box
				key={idx}
				ml="10px"
				position="relative"
				_before={
					data?.subs?.length
						? {
							content: "''",
							width: "10px",
							height: "1px",
							bg: "#d7d7d7",
							left: "-10px",
							top: data?.subs?.length ? "36px" : null,
							position: "absolute",
							transition: "all .3s ease",
						}
						: undefined
				}

				mb="20px"
			>
				<Box
					p="2"
					// cursor={"pointer"}
					// _hover={{ bg: "blue.100" }}
					// onClick={() => {
					// 	collectLinkData(data, null);
					// 	setLocationData(data);
					// }}
					// bg={data?.user_uuid === subsId ? greenBg : null}
					mb="2"
					borderWidth="1px"
					borderColor={"#d7d7d7"}
					borderRadius={"5px"}
				>
					<Text fontWeight={"600"}>{data?.link_location_title}</Text>
					<Text>{data?.link_location_name}</Text>
				</Box>
				<Box>
					{data?.subs?.length > 0
						? data.subs.map((subData, index) => (
							<Box
								position="relative"
								_before={{
									content: "''",
									width: "55px",
									height: "1px",
									bg: "#d7d7d7",
									left: "-45px",
									top: "36px",
									position: "absolute",
									transition: "all .3s ease",
								}}
								_after={{
											content: "''",
											width: "1px",
											height: "117%",
											bg: "#d7d7d7",
											left: "-46px",
											top: "-36px",
											position: "absolute",
											transition: "all .3s ease",
										}
								}
								key={index}
								_hover={{ bg: "blue.100", color:"black" }}
								ml="35px"
								p="2"
								mb="2"
								border="1px"
								borderRadius={"5px"}
								// borderColor={subData?.disabled ? 'red.400' : greenBg}
								borderColor={"#d7d7d7"}
								cursor={"pointer"}
								bg={
									subData?.user_uuid === subsId
										? greenBg
										: null
								}
								onClick={() => {
									collectLinkData(data, subData);
									setSubsId(subData?.user_uuid);
									setLocationData(data);
								}}
							>
								<Flex
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Text fontWeight={"300"}>
										{subData?.first_name}{" "}
										{subData?.last_name}
									</Text>
									<Box
										h="5px"
										w="5px"
										borderRadius={"50px"}
										bg={
											subData?.disabled
												? "red.400"
												: "green.400"
										}
									></Box>
								</Flex>
								<Text fontSize={"sm"}>
									{subData?.email}
								</Text>
							</Box>
						))
						: null}
				</Box>
			</Box>
		);
	});

	// Todo:Render Lingo Link List
	const renderLinkData =
		linkData && linkData.length > 0 ? (
			linkData.map((data, idx) => (
				<Box
					key={idx}
					bg={data?.uuid === linkUuid ? greenBg : null}
					ml="10px"
					position="relative"
					mb="10px"
					onClick={() => {
						setLinkUuId(data?.uuid);
						collectCallHistory(data);
					}}
				>
					<Box
						p="2"
						cursor="pointer"
						_hover={{ bg: "blue.100", color:"black" }}
						mb="2"
						border="1px"
						borderColor="#d7d7d7"
						borderRadius="5px"
					>
						<Flex
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Text fontWeight="200">{data?.title}</Text>
							<Box
								h="5px"
								w="5px"
								borderRadius={"50px"}
								bg={data?.active ? "green.400" : "red.400"}
							></Box>
						</Flex>
						<Text fontSize={"xs"}>{data?.full_url}</Text>
					</Box>
				</Box>
			))
		) : (
			<Flex
				w={"100%"}
				p={5}
				bg={redBg}
				borderLeftWidth={"5px"}
				borderLeftColor={"red.500"}
			>
				<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
					This Subscriber does not have any LingoLinks.
				</Heading>
			</Flex>
		);

	const renderSessionCalls = linkCalls => {
		if (linkCalls.length > 0) {
			return linkCalls?.map((item, index) => {
				return (
					<Flex
						w={"100%"}
						key={index}
						//bg={item?.type === "audio" ? "green.100" : "blue.100"}
						p={2}
						rounded={"md"}
						borderWidth={"0.05px"}
						borderColor={"gray.200"}
						bg={useListItemBgColor}
						color={useListItemTextColor}
					>
						<Flex minW={"30px"}>
							<Text>{index + 1}</Text>
						</Flex>
						<Flex minW={"50px"}>
							<Box
								w={"30px"}
								rounded={"md"}
								bg={
									item?.type === "audio"
										? "green.300"
										: "blue.300"
								}
								align={"center"}
								pt={1}
							>
								{item?.type === "audio" ? (
									<Icon h={5} w={5} as={AiTwotoneAudio} />
								) : (
									<Icon as={AiOutlineVideoCamera} />
								)}
							</Box>
						</Flex>
						<Flex w={"100%"} direction={"column"}>
							<Text fontSize={"sm"}>
								{moment(item?.started).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
								{" - "}
								{moment(item?.ended).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
							</Text>
							<Text>
								<b>{item?.source_language}</b>{", "}
								<b>{item?.target_language}</b>
								{item?.agent_info}
							</Text>
						</Flex>
						<Flex maxW={"80px"} minW={"80px"}>
							<Text>
								{formatSeconds(
									Math.round(item?.duration_seconds, 0)
								)}
							</Text>
						</Flex>
					</Flex>
				);
			});
		}
	};

	const renderSessions =
		callHistoryList !== null && callHistoryList?.length > 0 ? (
			callHistoryList?.map((item, index) => (
				<Flex
					w={"100%"}
					key={index}
					direction={"column"}
					p={2}
					mt={2}
					borderBottomWidth={"0.05px"}
					borderBottomColor={"gray.200"}
				>
					{/* <Flex w={"100%"}>
						<Flex w={"50px"}>
							<Text>{index + 1}</Text>
						</Flex>
						<Flex w={"100%"} direction={"column"}>
							<Text fontSize={"sm"}>
								{moment(item?.created).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
							</Text>
							<Text fontWeight={500} color={"gray.400"}>
								{item?.session}
							</Text>
						</Flex>
						<Flex w={"150px"} direction={"column"}>
							<Text>Total Calls: {item?.count}</Text>
						</Flex>
						<Flex w={"250px"} direction={"column"}>
							<Text>
								Call Duration:{" "}
								{formatSeconds(Math.round(item?.total, 2))}
							</Text>
						</Flex>
					</Flex> */}
					{item?.count > 0 ? (
						<Flex w={"100%"} direction={"column"}>
							{renderSessionCalls(item?.calls)}
						</Flex>
					) : null}
				</Flex>
			))
		) : (
			<Flex w={"100%"} h={"50px"}>
				<Text bg={"red.100"} py={3}>
					There are <b>no sessions</b> and{" "}
					<b>calls within those sessions</b> in this LingoLink
				</Text>
			</Flex>
		);

	const renderCallHistory =
		callHistoryList !== null && callHistoryList?.length > 0 ? (
			callHistoryList?.map((item, index) => (
				<Flex
					w={"100%"}
					key={index}
					direction={"column"}
					p={2}
					mt={2}
					borderBottomWidth={"0.05px"}
					borderBottomColor={"gray.200"}
					alignItems='center'
				>
					{/* <Flex w={"100%"}>
						<Flex w={"50px"}>
							<Text>{index + 1}</Text>
						</Flex>
						<Flex w={"100%"} direction={"column"}>
							<Text fontSize={"sm"}>
								{moment(item?.created).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
							</Text>
							<Text fontWeight={500} color={"gray.400"}>
								{item?.session}
							</Text>
						</Flex>
						<Flex w={"150px"} direction={"column"}>
							<Text>Total Calls: {item?.count}</Text>
						</Flex>
						<Flex w={"250px"} direction={"column"}>
							<Text>
								Call Duration:{" "}
								{formatSeconds(Math.round(item?.total, 2))}
							</Text>
						</Flex>
					</Flex> */}
					{item?.count > 0 ? (
						<Flex w={"100%"} direction={"column"} gap={1}>
							{renderSessionCalls(item?.calls)}
						</Flex>
					) : null}
				</Flex>
			))
		) : (
			<Flex w={"100%"} h={"50px"}>
				<Text w={"100%"} bg={"red.100"} py={3} color="black">
					There is not call history for this LingoLink.
				</Text>
			</Flex>
		);

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} pl={3}>
			<Card w={"100%"} p={2} mt={2}>
				{/* callHistoryList?.length ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)' */}
				<Flex h={'calc(100vh - 150px)'} transition="height .4s">
					<Box
						w={!configurationCollapseFlag ? "95%" : "75%"}
						bg={brandBg}
						p="1"
						borderRadius={"10px"}
					>
						<Flex
							mb="3"
							alignItems="center"
							justifyContent={"space-between"}
						>
							<Text fontWeight={"bold"} ml={1}>
								Location Specific Call History
							</Text>
							{cloneEventList?.length ? (
								<Input
									w="40%"
									placeholder="Search..."
									value={eventValue}
									onChange={e => {
										setEventValue(e.target.value);
									}}
								/>
							) : null}
						</Flex>
						<Flex
							justifyContent={"space-between"}
							h="100%"
							overflow={"hidden"}
						>
							<Flex w={'100%'} h={'95%'} direction={'column'}>
								<Box w={'100%'}
									h={callHistoryList?.length ? 'calc(100vh - 240px)' : 'calc(100vh - 150px)'} overflowY="auto" pr="2">
									{callHistoryLoader ? (
										<Flex
											h="50vh"
											alignItems={"center"}
											justifyContent={"center"}
										>
											<Spinner size="xl" />
										</Flex>
									) : (
										<>
											<Heading
												fontSize={"16px"}
												fontWeight={"600"}
												mb="2"
												borderBottomWidth={"1px"}
											>
												Total Calls:
												{callHistoryList?.length}
											</Heading>
											{renderCallHistory}
										</>
									)}
								</Box>
								{callHistoryList?.length ? (<Pagination currentPage={currentPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} setCurrentPage={setCurrentPage} recordsPerPage={recordsPerPage} />) : null}
							</Flex>

							{linkDataFlag ? (
								<Box
									w="30%"
									h="95%"
									borderLeftWidth={"1px"}
									pl="2"
									overflowY="auto"
								>
									{linkLoader ? (
										<Flex
											h="50vh"
											alignItems={"center"}
											justifyContent={"center"}
										>
											<Spinner size="xl" />
										</Flex>
									) : (
										<>
											<Flex
												w="100%"
												mb="2"
												borderBottomWidth={"1px"}
												justifyContent={"space-between"}
											>
												<Heading
													fontSize={"16px"}
													fontWeight={"600"}
												>
													LingoLinks:{" "}
													{linkData?.length}
												</Heading>
												<AiFillRightCircle
													cursor="pointer"
													size="20px"
													onClick={() => {
														setLinkdataFlag(
															!linkDataFlag
														);
													}}
												/>
											</Flex>
											{renderLinkData}
										</>
									)}{" "}
								</Box>
							) : (
								<Box
									w="5%"
									p="2"
									borderRadius="10px"
									bg={brandBg}
									h="95%"
									ml="2"
									pl="3"
									borderWidth='1px'
								>
									<Box w={"100%"} h="100%">
										<AiFillLeftCircle
											cursor="pointer"
											onClick={() => {
												setLinkdataFlag(!linkDataFlag);
											}}
											size="20px"
										/>
										<Text
											fontSize="16px"
											mt="3"
											css={{
												writingMode: "vertical-lr",
												textOrientation: "mixed",
											}}
											fontWeight={"600"}
										>
											LingoLinks
										</Text>
									</Box>
								</Box>
							)}
						</Flex>
					</Box>
					{configurationCollapseFlag ? (
						<Box
							w="25%"
							position="relative"
							borderRadius="15px"
							bg={brandBg}
							ml="2"
						>
							<Box
								position="absolute"
								top="0"
								right="0"
								mr="2"
								mt="3"
								zIndex="999"
							>
								<AiFillRightCircle
									cursor="pointer"
									size="20px"
									onClick={() => {
										setConfiguationCollapseFlag(
											!configurationCollapseFlag
										);
									}}
								/>
							</Box>
							<Box w="100%" h="100%">
								<Flex
									w={"100%"}
									direction={"column"}
									h="100%"
								>
									<Text
										fontSize={"lg"}
										fontWeight="600"
										mb="2"
										p={5}
										borderBottom={"1px"}
										borderColor={"#d7d7d7"}
									>
										Locations and Subscribers
									</Text>
									<Flex pb={2} px={4} justifyContent="center">
										<Box>
											<Menu>
												<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
													{sort ? sort === 'asc' ? 'By Name (Ascending)' : 'By Name (Descending)' : "Sort Locations"}
												</MenuButton>
												<MenuList onClick={(e) => setSort(e.target.value)}>
													<MenuItem value="asc">By Name (Ascending)</MenuItem>
													<MenuItem value="desc">By Name (Descending)</MenuItem>
												</MenuList>
											</Menu>
										</Box>
									</Flex>
									{/* <Button bg={"blue.100"}>
										Complete Billing
									</Button> */}
									{loading ? (
										<Flex
											alignItems="center"
											justifyContent="center"
											h="50vh"
										>
											<Spinner size="lg" />
										</Flex>
									) : locationList?.length ? (
										<Box p="5" overflow="auto"
											className="custom-scrollbar">{renderLocationList}</Box>
									) : (
										<Flex
											w={"100%"}
											p={5}
											bg={redBg}
											borderLeftWidth={"5px"}
											borderLeftColor={"red.500"}
										>
											<Heading
												w={"100%"}
												fontSize={"16px"}
												fontWeight={"normal"}
											>
												No Locations and Subscriber
												found..
											</Heading>
										</Flex>
									)}
								</Flex>
							</Box>
						</Box>
					) : (
						<Box
							w="5%"
							p="2"
							borderRadius="10px"
							bg={brandBg}
							h="100%"
							ml="2"
							pl="3"
						>
							<Box w={"100%"} h="100%">
								<AiFillLeftCircle
									cursor="pointer"
									onClick={() => {
										setConfiguationCollapseFlag(
											!configurationCollapseFlag
										);
									}}
									size="20px"
								/>
								<Text
									fontSize="16px"
									mt="3"
									css={{
										writingMode: "vertical-lr",
										textOrientation: "mixed",
									}}
									fontWeight={"600"}
								>
									Locations and Subscribers
								</Text>
							</Box>
						</Box>
					)}
				</Flex>
			</Card>
		</Flex>
	);
};

export default LocationCallHistory;
