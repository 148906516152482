import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'

const DeviceData = ({devicesList}) => {
    if(!devicesList || !devicesList?.length) return null

  return (
    <>
        {devicesList?.map((item, index) => (
      <Flex key={index}
        w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
        <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
          <Text rounded={'md'} bg={item?.deviceObj?.assigned ? 'green.200' : null} pl={2} color="black">
            {moment(item?.assigned_date).format("MMM DD,YYYY,HH:mm a")}
          </Text>
        </Box>
        <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
          {item?.deviceObj?.type} ({item?.deviceObj?.class})
        </Box>
        <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
          <Text fontSize={'sm'}>{item?.deviceObj?.serial}</Text>
        </Box>
        <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
          <Text fontSize={'sm'}>{item?.deviceObj?.mac}</Text>
        </Box>
        <Box minW={'90px'} fontSize={'xs'} ml={1} pl={2} color="black" alignItems={'center'}>
          {item?.is_locked
            ? <Text p={1} bg={'green.100'} rounded={'md'}>Yes</Text>
            : <Text p={1} bg={'red.100'} rounded={'md'}>No</Text>}
        </Box>
        {/* <Box minW={'155px'} fontSize={'sm'} ml={1} pl={2} color="black">
          {item?.is_activated 
            ? <Flex w={'100%'} p={1} direction={'column'} bg={'green.100'} rounded={'md'}>
              <Text>Yes</Text>
              <Text fontSize={'xs'}>On ({moment(new Date()).format("MMM DD,YYYY,HH:mm a")})</Text>
            </Flex>
            : <Text p={1} bg={'red.100'} rounded={'md'}>No</Text>}
        </Box> */}
        {/* <Box minW={'100px'} fontSize={'sm'} ml={1} pl={2}>
          {item?.activation_code}
        </Box> */}
      </Flex>
    ))}
    </>
  )
}

export default DeviceData