// React and Chakra Imports
import React from "react";
import {
	Box,
	SimpleGrid,
	Flex,
	Icon,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	MdPerson,
	MdPhone,
	MdMan,
	MdVideoCall,
	MdLocationOn,
} from "react-icons/md";

// Component Imports
import IconBox from "../../../../components/icons/IconBox";
import Card from "../../../../components/card/Card";
import MiniStatistics from "../../../../components/card/MiniStatistics";


function LinkMetrics({locationStats, loading}) {
	const brandColor = useColorModeValue("brand.500", "white");
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.400");

	return (
		<Flex w={"100%"} direction={"column"}>
			<Card w={"100%"} mt={2}>
				<SimpleGrid mt={5} columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap="10px" mb="10px">
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="32px" h="32px" as={MdLocationOn} color={brandColor} />}
							/>
						}
						name="Total Locations"
						value={locationStats?.total_locations}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="32px" h="32px" as={MdLocationOn} color={'green.500'} />}
							/>
						}
						name="Active Locations"
						value={locationStats?.total_active_locations}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="28px" h="28px" as={MdLocationOn} color="red.500" />}
							/>
						}
						name="Closed Locations"
						value={locationStats?.total_closed_locations}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="28px" h="28px" as={MdMan} color={brandColor} />}
							/>
						}
						name="Total Subscribers"
						value={locationStats?.total_subscribers}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="28px" h="28px" as={MdMan } color="green.500" />}
							/>
						}
						name="Active Subscribers"
						value={locationStats?.total_active_subscribers}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="28px" h="28px" as={MdMan} color="red.500" />}
							/>
						}
						name="Disabled Subscribers"
						value={locationStats?.total_disabled_subscribers}
						loading={loading}
					/>
					<MiniStatistics
						launchUrl='/account/locations'
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={<Icon w="28px" h="28px" as={MdMan} color={brandColor} />}
							/>
						}
						name="Subscriber Locations"
						value={locationStats?.total_sub_locations}
						loading={loading}
					/>
				</SimpleGrid>
			</Card>
		</Flex>
	);
}

export default LinkMetrics;
