import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import {
    Box,
    Button,
    Input,
    Heading,
    Spinner,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Select,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    InputLeftAddon,
    InputGroup,
    Spacer,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineSearch } from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";

// Api Call Imports
import {useBrandBg, useGreenBg, useRedBg, useNoDataBandBg, useTextColor } from "../../../theme/globalColorTheme";
import { fetchLocationsAndSubs, fetchAccountEvents } from "../../../services/accountServices";
import Pagination from "../../../components/pagination/Pagination";
import { INIT_PAGE, INIT_RECORDS_PER_PAGE } from "./constants";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { format } from "date-fns";
import { ChevronDownIcon } from "@chakra-ui/icons";

const LocationEvents = (props) => {
    const redBg = useRedBg()
    const greenBg = useGreenBg()
    const [loading, isLoading] = useState(false);
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [eventValue, setEventValue] = useState("");
    const [subsId, setSubsId] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [cloneEventList, setCloneEventList] = useState([])
    const [eventLoader, setEventLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(INIT_PAGE);
    const [totalPages, setTotalPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(INIT_RECORDS_PER_PAGE)
    const [currLocUuid, setCurrLocUuid] = useState('')

    const [emailColFilter, setEmailColFilter] = useState('')
    const [routeColFilter, setRouteColFilter] = useState('')
    const [statusColFilter, setStatusColFilter] = useState('')
    const [tableHeaders, setTableHeaders] = useState({ Email: [], Route: [], Status: [], Date: [] })
    const [filteredEventsData, setFilteredEventsData] = useState([])
    const [selectedDates, setSelectedDates] = useState([new Date("05-01-2024"), new Date()])
    const [sort, setSort] = useState('')

    const blackWhiteColor = useTextColor()


    const handleUpdateColumnfilterOptions = () => {
        const newUniqueRouteTypes = new Set();
        const newUniqueEmails = new Set()
        const newUniqueStatus = new Set()

        filteredEventsData?.forEach(eventItem => {
            newUniqueRouteTypes.add(eventItem?.event_route);
            newUniqueEmails.add(eventItem?.event_caller)
            if (!eventItem.event_status) {
                newUniqueStatus.add('Fail')
            } else {
                newUniqueStatus.add('Success')
            }
        });
        setTableHeaders({ Email: [...newUniqueEmails], Route: [...newUniqueRouteTypes], Status: [...newUniqueStatus], Date: [] })
    }

    const handleUpdateFiltereEventdData = () => {
        const filteredEventData = eventsList[0]?.events?.filter(
            event => (
                (emailColFilter === '' || event?.event_caller.includes(emailColFilter))
                && (routeColFilter === '' || event?.event_route.includes(routeColFilter))
                && (statusColFilter === '' || String(event?.event_status) === statusColFilter)))
        setFilteredEventsData(
            filteredEventData
        )
    }

    const renderTableFilter = (placeholder, options) => {
        if (placeholder === "Date") {
            return (<RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />)
        }
        if (options.length) {
            if (placeholder === 'Status') {
                return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                    {options?.map(option => <option key={option} value={option === 'Success'}>{option}</option>)}
                </Select>)
            }
            return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                {options?.map(option => <option key={option} value={option}>{option}</option>)}
            </Select>)
        }
    }

    const handleColumnFilterChange = (value, col) => {
        switch (col) {
            case "Email":
                setEmailColFilter(value)
                break;
            case "Route":
                setRouteColFilter(value)
                break
            case "Status":
                setStatusColFilter(value)
                break
            default:
                break;
        }
    }

    useEffect(() => {
        handleUpdateColumnfilterOptions()
    }, [filteredEventsData])

    useEffect(() => {
        handleUpdateFiltereEventdData()
    }, [emailColFilter, statusColFilter, routeColFilter, eventsList])



    useEffect(() => {
        collectLocationWithSubs(null);
    }, [])

    useEffect(() => {
        collectEventsData(currLocUuid, subsId);
    }, [currentPage, recordsPerPage, currLocUuid, subsId]);



    const collectLocationWithSubs = (currentSelection) => {
        isLoading(true);
        let paramObj = {
            link_account_uuid: null,
            key: currentSelection,
            records_per_page: 0,
            page_id: 0,
        };
        fetchLocationsAndSubs(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.locations) {
                    isLoading(false);
                    setLocationList(res?.data[0]?.locations);
                } else {
                    isLoading(false);
                    setLocationList([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setLocationList([]);
            });
    };


    const collectEventsData = (locationUuid, subscriberUuid) => {
        if (locationUuid != null && subscriberUuid != null) {
            setEventLoader(true)
            let paramObj = {
                location_uuid: locationUuid,
                subscriber_uuid: subscriberUuid,
                "filter_key": "string",
                "filter_value": "string",
                "filter_choice": "today",
                "start_date": format(selectedDates[0], 'dd-MM-yyyy'),
                "end_date": format(selectedDates[1], 'dd-MM-yyyy'),
                "duration": "all",
                page_id: currentPage,
                limit: recordsPerPage
            };
            fetchAccountEvents(paramObj)
                .then(res => {
                    setEventLoader(false)
                    if (res?.data?.length) {
                        isLoading(false);
                        setEmailColFilter('')
                        setStatusColFilter('')
                        setRouteColFilter('')
                        setEventsList(res?.data);
                        setCloneEventList(res?.data[0]?.events)
                        setTotalPages(res?.data[0]?.total_pages)
                    } else {
                        setEventsList([]);
                        setCloneEventList([]);
                    }
                })
                .catch(err => {
                    setEventLoader(false)
                    setLocationList([]);
                    setEventsList([]);
                    setCloneEventList([]);
                });
        }
    };

    function sortLocations() {
        if (locationList && locationList.length > 1 && sort !== '') {

            const tempLocations = [...locationList].sort((a, b) => {
                if (sort === 'asc') {
                    if (a.is_sub_location !== b.is_sub_location) {
                        return a.is_sub_location - b.is_sub_location;
                    }
                    return a.link_location_title.localeCompare(b.link_location_title);
                } else if (sort === 'desc') {
                    if (a.is_sub_location !== b.is_sub_location) {
                        return a.is_sub_location - b.is_sub_location;
                    }
                    return b.link_location_title.localeCompare(a.link_location_title);
                }
                return 0;
            })

            setLocationList(tempLocations)
        }
    }

    useEffect(() => {
        if (eventValue?.length) {
            const filteredEvents = eventsList.filter(event => {
                return Object.values(event).some(value =>
                    String(value).toLowerCase().includes(eventValue.toLowerCase())
                );
            });
            setCloneEventList(filteredEvents)
        }
        else {
            setCloneEventList(eventsList)
        }
    }, [eventValue])

    const RenderEventsList = memo(() => {
        return (
            filteredEventsData !== null && filteredEventsData?.length > 0 ? (
                filteredEventsData?.map((eventItem, index) => (
                    <Tr key={index + eventItem?.datetime_created}>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
                        <Td color='black' bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
                    </Tr>
                ))
            ) : (
                null
            )
        )
    })

    useEffect(() => {
        sortLocations()
    }, [sort])

    // Todo:Render Location List
    const renderLocationList =
        locationList?.map((data, idx) => {
            return (
                <Box key={idx}
                    ml="10px"
                    position="relative"
                    _before={
                        data?.subs?.length ? {
                            content: "''",
                            width: '10px',
                            height: "1px",
                            bg: "#d7d7d7",
                            left: '-10px',
                            top: data?.subs?.length ? '36px' : null,
                            position: "absolute",
                            transition: "all .3s ease",
                        } : undefined}
                    _after={
                        data?.subs?.length ? {
                            content: "''",
                            width: '1px',
                            height: data?.subs?.length <= 1 ? '75px' : "80%",
                            bg: "#d7d7d7",
                            left: '-10px',
                            top: data?.subs?.length ? "37px" : null,
                            position: "absolute",
                            transition: "all .3s ease",
                        } : undefined}
                    mb="5px" >
                    <Box p="2"
                        overflow={'hidden'}
                        // cursor={'pointer'}
                        // _hover={{'bg': 'blue.100'}}
                        // // bg={data?.user_uuid === subsId ? greenBg : null}
                        // onClick={() => {
                        //     collectEventsData(data?.link_location_uuid)
                        // }}
                        mb='2' border="1px" borderColor={'#d7d7d7'} borderRadius={'5px'}>
                        <Text fontWeight={'600'}>{data?.link_location_title}</Text>
                        <Text>{data?.link_location_name}</Text>
                    </Box>
                    <Box>
                        {
                            data?.subs?.length > 0 ? (
                                data.subs.map((subData, index) => (
                                    <Box
                                        position="relative"
                                        _before={{
                                            content: "''",
                                            width: '55px',
                                            height: "1px",
                                            bg: "#d7d7d7",
                                            left: '-55px',
                                            top: "36px",
                                            position: "absolute",
                                            transition: "all .3s ease",
                                        }}
                                        key={index}
                                        _hover={{ 'bg': 'blue.100', color:"black" }}
                                        ml="45px" p="2" mb='2' border="1px"
                                        borderRadius={'5px'}
                                        // borderColor={subData?.disabled ? 'red.400' : greenBg}
                                        borderColor={'#d7d7d7'}
                                        cursor={'pointer'}
                                        bg={subData?.user_uuid === subsId ? greenBg : null}
                                        onClick={() => {
                                            collectEventsData(data?.link_location_uuid, subData?.sub_uuid)
                                            setSubsId(subData?.sub_uuid)
                                            setCurrLocUuid(data?.link_location_uuid)
                                        }}
                                    >
                                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                                            <Text fontWeight={'600'}>{subData?.first_name} {subData?.last_name}</Text>
                                            <Box h="5px" w="5px" borderRadius={'50px'} bg={subData?.disabled ? 'red.400' : 'green.400'}></Box>
                                        </Flex>
                                        <Text>{subData?.email}</Text>
                                    </Box>
                                ))
                            ) : null
                        }
                    </Box>
                </Box >
            );
        });

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'} p={2} mt={2}>
                <Flex h={cloneEventList.length ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)'} transition="height .4s">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "75%"}
                        bg={brandBg}
                        p="1"
                        borderRadius={"10px"}
                        overflowY="auto"
                    >
                        <Flex mb="3" alignItems="center" justifyContent={'space-between'}>
                            <Text fontWeight={'bold'} ml={1} >Events</Text>
                            <InputGroup w="40%">
                                <InputLeftAddon><AiOutlineSearch /></InputLeftAddon>
                                <Input w="95%" placeholder='Search...' value={eventValue} onChange={(e) => { setEventValue(e.target.value) }} color={blackWhiteColor}/>
                            </InputGroup>
                                
                        </Flex>
                        {eventLoader ? (
                            <Flex alignItems="center" justifyContent="center" h="50vh">
                                <Spinner size="lg" />
                            </Flex>
                        ) :
                            cloneEventList?.length ? (
                                <TableContainer border="1px solid #d7d7d7" borderRadius="5px">
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                {Object.keys(tableHeaders).map(header => (
                                                    <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                        {header}
                                                    </Th>
                                                ))}
                                            </Tr>
                                            <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                {Object.entries(tableHeaders).map(([header, options]) => (
                                                    <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                        {renderTableFilter(header, options)}
                                                    </Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <RenderEventsList />

                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            ) : <Flex w={"100%"} p={5} bg={useNoDataBandBg} borderLeftWidth={"10px"}
                                borderLeftColor={"red.500"}>
                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                    No Event Data based on selection..
                                </Heading>
                            </Flex>}
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="25%"
                            position="relative"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="100%">
                                <Flex w={'100%'} direction={'column'}
                                    h="100%">
                                    <Text fontSize={'lg'} fontWeight="600" mb="2" p={5} borderBottom={'1px'} borderColor={'#d7d7d7'}>
                                        Locations and Subscribers
                                    </Text>
                                    <Flex pb={2} px={4} justifyContent="space-between" w="full" flexWrap="wrap" gap={1}>
                                            <Box  w="40%">
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{sort ? sort==="asc" ? "By Name (Ascending)" : "By Name (Descending)" : "Sort Locations"}</Text>
                                                </MenuButton>
                                                <MenuList onClick={e => setSort(e.target?.value)}>
                                                    <MenuItem value="asc">By Name (Ascending)</MenuItem>
                                                    <MenuItem value="desc">By Name (Descending)</MenuItem>
                                                </MenuList>
                                            </Menu>
                                            </Box>
                                            <Button onClick={() => collectEventsData()} bg={'blue.100'} p={2} color="black" _hover={{color:blackWhiteColor, bg:"revert"}}>
                                                All Events
                                            </Button>
                                    </Flex>
                                    {loading ? (
                                        <Flex alignItems="center" justifyContent="center" h="50vh">
                                            <Spinner size="lg" />
                                        </Flex>
                                    ) :
                                        locationList?.length ?
                                            <Box p="2" overflow="auto"
                                                h="72%"
                                                className="custom-scrollbar">
                                                {renderLocationList}
                                            </Box> :
                                            (
                                                <Flex w={"100%"} p={5} bg={redBg} borderLeftWidth={"5px"} borderLeftColor={"red.500"}>
                                                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                        No Locations and Subscriber found..
                                                    </Heading>
                                                </Flex>
                                            )}

                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="3"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                    fontWeight={'600'}
                                >
                                    Locations and Subscribers
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
            {cloneEventList?.length ? (<Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage} />) : null}
        </Flex>
    );
}

export default LocationEvents;
