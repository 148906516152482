//used in LinkSubscriberCalls & LinkSubscriberManager

export const INIT_PAGE = 1
export const INIT_RECORDS_PER_PAGE = 5

//used in LinkSubscriberCalls

export const INIT_SELECTED_VALUES = ['human', 'basicai']
export const INIT_CURRENT_SELECTION = 'Active'
export const INIT_LINK_CHOICE = 'active'

export const lingoCardFilters = {
    ACTIVE: 'active',
    DISABLE: 'disable'
}

export const DRAWER = {
    ADDUPDATECARD: 'addUpdateCard',
    SHARECARD: 'shareCard',
    CARD_DETAILS: 'cardDetails',
    SHARESMS:'sharesms'
}

export const SCHEDULE_DETAILS = {
    "service_type": "Translator",
    "content_type": "Conversation",
    "requested_date": "",
    "requested_time": "",
    "request_duration": 60,
    "request_frequency": "daily"
}

export const SCHEDULE_SERVICE_TYPES = {
    "TRANSLATOR":"Translator",
    "INTERPRETER":'Interpreter',
    "TRANSCRIBER":'Transcriber'
}

export const SCHEDULE_FREQUENCY = {
    ONCE:"once",
    DAILY:"daily",
    WEEKLY:"weekly",
    MONTHLY:"monthly",
    CUSTOM:"custom"
}

export const SCHEDULE_CONTENT_TYPE = {
    CONVERSATION:"Conversation",
    DOCUMENT:"Document"
}

export const SCHEDULE_STATUS = {
    OPEN:'open',
    CLOSED:'closed',
    CANCELED:"cancel"
}

export const SIDEBAR_TYPES = {
    CREATE:"create",
    UPDATE:"update"
}

export const LINGOLINK_TYPES = {
    "NO_LIMIT":"noLimit",
    "SEVEN_DAYS_TRIAL":"sevenDaysTrial",
    "FIXED_DURATION":"fixedDuration"
}

export const LANG_PREF_TYPES = {
    ACTIVE:"active",
    DISABLE:"disabled"
}

export const CALL_LIST_TYPE = {
    ONLY_CALLS:"callOnly",
    CALLS_WITH_SESSION:"callWithSession"
}