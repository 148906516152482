import React, { memo, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	Box,
	Button,
	Input,
	Heading,
	Spinner,
	Flex,
	Text,
	Icon,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	useToast,
	Spacer,
	Badge,
} from "@chakra-ui/react";
import {
	AiFillLeftCircle,
	AiFillRightCircle,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineCheckCircle,
	AiTwotonePhone,
} from "react-icons/ai";

// Component Imports
import { formatTime, formatSeconds } from "../../../utils/TimeFunctions";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
	useGreenButton,
	useNoResultFoundText,
	useRedButton,
	useTextColor,
	useListItemBgColor,
	useListItemTextColor,
} from "../../../theme/globalColorTheme";
import {
	fetchLocationsAndSubs,
	fetchAccountEvents,
	fetchSubscriberLinks,
	fetchSubscriberLinksCallHistory,
	getAccLinkHistory,
} from "../../../services/accountServices";
import Pagination from "../../../components/pagination/Pagination";
import { CALL_LIST_TYPE, LINGO_LINKS_FILTER } from "./constants";
import { calculateUserLinkCalls, getAllLinks, getAllUserLinkCalls } from "../../../services/businessServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";


const RenderSessions = memo(({ linkSessions, listTypeFilter }) => {
	const notFoundText = useNoResultFoundText()
	return linkSessions !== null && linkSessions?.length > 0
		? linkSessions?.map((item, index) => (
			<Flex w={'100%'} key={index}
				direction={'column'} gap={2} mt={1}>
				{listTypeFilter === CALL_LIST_TYPE.CALLS_WITH_SESSION &&

				<Flex w={'100%'} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'} p={2} >
					<Flex w={'50px'}>
						<Text >{index + 1}</Text>
					</Flex>
					<Flex w={'250px'}>
						<Text fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
					</Flex>
					<Flex w={'100%'}>
						<Text fontWeight={500} color={'gray.400'}>{item?.session}</Text>
					</Flex>
					<Flex w={'150px'} direction={'column'}>
						<Text>Total Calls: {item?.count}</Text>
					</Flex>
					<Flex w={'250px'} direction={'column'}>
						<Text>Call Duration: {formatSeconds(Math.round(item?.total, 2))}</Text>
					</Flex>

				</Flex>}
				{item?.count > 0
					? <Flex w={'100%'} direction={'column'} gap={1}>
						<RenderCallHistory callSessions={item?.calls} />
					</Flex> : null}
			</Flex>
		))
		: <Flex w={'100%'} p={2}>
			<Text w={'100%'} bg={'red.100'} py={3} color={notFoundText}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this LingoLink</Text>
		</Flex>;
})

const RenderCallHistory = memo(({ callSessions,rendering_child_call = false }) => {
	if (callSessions.length > 0) {
		return callSessions?.map((item, index) => {
			return (
				<Flex direction="column">
				<Flex
					w={"100%"}
					key={index} // #120303  f2f2f2
					//bg={item?.type === "audio" ? "#120303" : "blue.50"}					
					p={2}
					rounded={"md"}
					borderWidth={"0.05px"}
					borderColor={"gray.200"}
					bg={useListItemBgColor}
					color={useListItemTextColor}
				>
					<Flex minW={"30px"} alignItems="center">
						<Text>{index + 1}</Text>
					</Flex>
					<Flex minW={"50px"} alignItems="center">
						<Box
							w={"30px"}
							rounded={"md"}
							bg={
								item?.type === "audio"
									? "green.300"
									: "blue.300"
							}
							align={"center"}
							pt={1}
						>
							{ rendering_child_call 
								? <Icon h={5} w={5} as={AiTwotonePhone} /> : item?.type === "audio" ? (
								<Icon h={5} w={5} as={AiTwotoneAudio} />
							) : (
								<Icon as={AiOutlineVideoCamera} />
							)}
						</Box>
					</Flex>
					<Flex w={"100%"} direction={"column"}>
						<Text fontSize={"sm"}>
							{moment(item?.started).format(
								"MMM DD,YYYY,HH:mm:ss a"
							)}
							{" - "}
							{moment(item?.ended).format(
								"MMM DD,YYYY,HH:mm:ss a"
							)}
						</Text>
						<Text>
							<b>{item?.source_language}</b>{", "}
							<b>{item?.target_language} </b>
							{item?.agent_info
							? item?.type === "audio" ? <b> Agent: {JSON.parse(item?.agent_info?.replace(/'/g, '"'))?.agent}</b>
							: <Flex flexDir="column" fontSize="small">
								<Text>Agent Company: <b>{JSON.parse(item?.agent_info)?.company || "N/A"}</b></Text>
								<Text>Agent name: <b>{JSON.parse(item?.agent_info)?.name || "N/A"}</b></Text>
							</Flex>
							: null}
						</Text>
					</Flex>
					<Flex maxW={"80px"} minW={"80px"}>
						<Text>
							{formatSeconds(
								Math.round(item?.duration_seconds, 0)
							)}
						</Text>
					</Flex>
				</Flex>
				{item?.child_calls && item?.child_calls.length > 0 && (
                        <Flex w={'100%'} direction={'column'} pl={8} pt={2}>
							<RenderCallHistory callSessions={item?.child_calls} rendering_child_call/>
                                </Flex>
                            )}
				</Flex>
			);
		});
	}
})


const AccountCallHistory = props => {
	const redBg = useRedButton();
	const greenBg = useGreenButton();
	const [linkFilter, setLinkFilter] = useState(LINGO_LINKS_FILTER.ACTIVE)
	const [linksPageId, setLinksPageId] = useState(1)
	const [links, setLinks] = useState(null)
	const toast = useToast()
	const [linkreport, setLinkReport] = useState([]);
	const [linksLoading, setLinksLoading] = useState(true)
	const [isLoadMoreDisabled, setLoadMoreDisabled] = useState(false)
	const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false)
	const [linkSessions, setLinkSessions] = useState(null)
	const [selectedLingoLink, setSelectedLingoLink] = useState('')
	const [linkHistoryLoader, setLinkHistoryLoader] = useState(false)
	const [billAmount, setBillAmount] = useState(0.0);
	const [listTypeFilter, setListTypeFilter] = useState(CALL_LIST_TYPE.ONLY_CALLS)
	const blackWhiteText = useTextColor()

	const calculateBilling = (link) => {
		let linkUuid = link?.uuid
		let linkCode = link?.code
		let linkKey = link?.key
		if (linkUuid != null && linkCode != null && linkKey != null) {
			// isLoading(true);
			let paramObj = {
				linkUuid: linkUuid,
				linkKey: linkKey
			};
			calculateUserLinkCalls(paramObj)
				.then((res) => {
					// isLoading(false);
					if (res?.data[0]?.status) {
						// isLoading(false);
						setBillAmount(res?.data[0]?.amount);
						// mergeBillingData(res?.data[0]?.sessions);
					} else {
						// isLoading(false);
					}
				})
				.catch((err) => {
					// isLoading(false);
				});
		}
	};


	async function getAllLingoLinks() {
		try {
			const paramObj = {
				filter_key: "",
				filter_value: linkFilter,
				page_id: linksPageId,
				records_per_page: 10,
			};

			links?.length ? setShowLoadMoreLoading(true) : setLinksLoading(true);

			const resp = await getAllLinks(paramObj);

			const records = resp.data[0]?.records;

			if (!records?.length) {
				setLoadMoreDisabled(true);
				setLinksLoading(false);
				setShowLoadMoreLoading(false);
				return;
			}

			setLoadMoreDisabled(false);
			setLinks(prevLinks => links?.length ? [...prevLinks, ...records] : records);

		} catch (err) {
			toast(toastFunctionToaster("Failed to get lingoLinks", "error"));
			setLinks([]);
		} finally {
			setLinksLoading(false);
			setShowLoadMoreLoading(false);
		}
	}

	function getLinkHistory(uuid, key) {
		const paramObj = {
			page_id: 1,
			records_per_page: 10,
			linkUuid: uuid,
			"linkKey": key
		}
		setLinkHistoryLoader(true)
		getAllUserLinkCalls(paramObj).then(res => {
			if (res?.data?.[0]?.sessions?.length) {
				setLinkSessions(res?.data?.[0]?.sessions);
				setLinkReport(res?.data[0]?.report);
			} else {
				setLinkSessions([])
			}
		}).catch(err => {
			toast(toastFunctionToaster("Failed to get Link history", "error"))
			setLinkSessions([])

		}).finally(() => {
			setLinkHistoryLoader(false)
		})
	}

	function handleLoadMoreLinks() {
		setLinksPageId(linksPageId => linksPageId + 1)
	}

	function handleShowLinkBilling(item) {
		setSelectedLingoLink(item?.uuid)
		getLinkHistory(item?.uuid, item?.key)
	}

	useEffect(() => {
		getAllLingoLinks()
	}, [linkFilter, linksPageId])

	useEffect(() => {
		setLinksPageId(1)
		setLinks([])
		setSelectedLingoLink(null)
		setLinkSessions(null)
	}, [linkFilter])


	return (
		<Flex pt={{ sm: "125px", lg: "75px" }} pl={3} h={'94vh'}>
			
			<Card w={"80%"} mt={2} mr={2} h="100%">
			{linkSessions?.length > 0
                            ? <Flex alignItems="center" p={1}>
                                <Spacer />
                                <Box
                                    p={1}
                                    align={"center"}
                                    w={"170px"}
                                    _hover={{ cursor: "pointer" }}
                                    roundedLeft={"lg"}
                                    bg={listTypeFilter === CALL_LIST_TYPE.CALLS_WITH_SESSION ? "green.600" : greenBg}
                                    color={listTypeFilter === CALL_LIST_TYPE.CALLS_WITH_SESSION ? "white" : "inherit"}
                                    size="sm"
                                    onClick={() => {
                                        setListTypeFilter(CALL_LIST_TYPE.CALLS_WITH_SESSION);
                                    }}>
                                    {listTypeFilter === CALL_LIST_TYPE.CALLS_WITH_SESSION ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                    Calls with Session
                                </Box>
                                <Box
                                    p={1}
                                    align={"center"}
                                    w={"100px"}
                                    _hover={{ cursor: "pointer" }}
                                    roundedRight={"lg"}
                                    bg={listTypeFilter === CALL_LIST_TYPE.ONLY_CALLS ? "green.600" : greenBg}
                                    color={listTypeFilter === CALL_LIST_TYPE.ONLY_CALLS ? "white" : "inherit"}
                                    size="sm"
                                    onClick={() => {
                                        setListTypeFilter(CALL_LIST_TYPE.ONLY_CALLS);
                                    }}>
                                    {listTypeFilter === CALL_LIST_TYPE.ONLY_CALLS ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                    Calls only
                                </Box>
                            </Flex> : null}
				{
					linkHistoryLoader ? <Flex w="100%" justifyContent="center">
						<Spinner
							thickness='2px'
							speed='0.65s'
							size='xl'
						/>
					</Flex> : <>
						{!selectedLingoLink ? <Flex
							w={"100%"}
							p={5}
							bg={'red.100'}
							borderLeftWidth={"5px"}
							borderLeftColor={"red.500"}
						>
							<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
								Click the lingo link on the right for link usage history.
							</Heading>
						</Flex> :
							<>
								{linkSessions?.length ? <>
									<Flex
										h="100%"
										w={"100%"}
										direction={"column"}
										p={2}
										mt={2}
										borderBottomWidth={"0.05px"}
										borderBottomColor={"gray.200"}
										alignItems='center'
										overflow="hidden"
									>
										{/* <Flex w={"100%"}>
						<Flex w={"50px"}>
							<Text>{index + 1}</Text>
						</Flex>
						<Flex w={"100%"} direction={"column"}>
							<Text fontSize={"sm"}>
								{moment(item?.created).format(
									"MMM DD,YYYY,HH:mm:ss a"
								)}
							</Text>
							<Text fontWeight={500} color={"gray.400"}>
								{item?.session}
							</Text>
						</Flex>
						<Flex w={"150px"} direction={"column"}>
							<Text>Total Calls: {item?.count}</Text>
						</Flex>
						<Flex w={"250px"} direction={"column"}>
							<Text>
								Call Duration:{" "}
								{formatSeconds(Math.round(item?.total, 2))}
							</Text>
						</Flex>
					</Flex> */}
										<Flex w={"100%"} direction={"column"} position="relative" h="100%" overflowY="hidden">
										<Flex w={"100%"} direction={"column"} h="100%" overflowY="auto" className="custom-scrollbar" pb={"20"}>
											<RenderSessions linkSessions={linkSessions} listTypeFilter={listTypeFilter}/>
										</Flex>
											{linkreport?.length > 0
												? <Flex w={'100%'} h={'content'} bg={'gray.300'} align={'center'} fontSize="smaller" position="absolute" bottom={0} right={0} p={2} borderRadius="md" color="black">
													<Box align={'start'}>
														<Text ml={1}>Total Sessions: <Badge color={blackWhiteText} bg={greenBg}>{linkreport[0]}</Badge></Text>
													</Box>
													<Box align={'start'} ml={2}>
														<Text ml={1}>Total Calls (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>{linkreport[1]}</Badge></Text>
													</Box>
													<Box align={'start'} ml={2}>
														<Text ml={1}>Total Time used (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>{formatSeconds(Math.round(linkreport[2], 2))}</Badge></Text>
													</Box>
													<Spacer />
													{billAmount >= 0.0
														? <Box align={'end'} ml={2}>
															<Text ml={1}>Total Charged $ Amount (in all sessions): <Badge color={blackWhiteText} bg={greenBg}>${billAmount.toFixed(2)}</Badge></Text>
														</Box> : null}
														<Box
                                                            onClick={() => calculateBilling()}
                                                            align={'end'} ml={2} mr={'1'}>
                                                            <Button size={'xs'} colorScheme="green">Calculate Amount</Button>
                                                        </Box>
												</Flex>
												: <Flex py={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
													<Text ml={1}>No Report...</Text>
												</Flex>}
										</Flex>
									</Flex>
								</> : <Flex
									w={"100%"}
									p={5}
									bg={'red.100'}
									borderLeftWidth={"5px"}
									borderLeftColor={"red.500"}
								>
									<Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
										This Lingo link does not have any usage.
									</Heading>
								</Flex>}
							</>
						}
					</>
				}

			</Card>
			<Card w={"20%"} mt={2} p={2}>
				<Flex flexDir="column" w="full" alignItems="center" h="100%">
					<Text as="h3" fontWeight="bold" fontSize="large">LingoLinks</Text>
					<Flex align={"center"} p={2} w="full">
						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedLeft={"lg"}
							bg={linkFilter === LINGO_LINKS_FILTER.ACTIVE ? "green.600" : greenBg}
							color={linkFilter === LINGO_LINKS_FILTER.ACTIVE ? "white" : "inherit"}
							size="sm"
							onClick={() => {
								setLinkFilter(LINGO_LINKS_FILTER.ACTIVE);
							}}>
							{linkFilter === LINGO_LINKS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Active
						</Box>

						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedRight={"lg"}
							bg={linkFilter === LINGO_LINKS_FILTER.CLOSED ? "red.400" : redBg}
							color={linkFilter === LINGO_LINKS_FILTER.CLOSED ? "white" : "inherit"}
							size="sm"
							onClick={() => {
								setLinkFilter(LINGO_LINKS_FILTER.CLOSED);
							}}>
							{linkFilter === LINGO_LINKS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Closed
						</Box>
					</Flex>
					<Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
					>
						<Flex flexDir="column" minH="100%">
							{
								linksLoading ? <Flex w="100%" justifyContent="center">
									<Spinner
										thickness='2px'
										speed='0.65s'
										size='xl'
									/>
								</Flex> :
									<Flex flexDir="column">
										{
											(links && links?.length) ? links.map((link) => (
												<Flex flexDir="column" gap={1} p="2"
													cursor="pointer"
													_hover={{ bg: "blue.100", color:'black' }}
													mb="2"
													borderWidth="1px"
													borderColor="#d7d7d7"
													borderRadius="5px"
													bg={selectedLingoLink === link.uuid ? greenBg : null}
													onClick={() => handleShowLinkBilling(link)}
												>
													<Text fontWeight="bold">{link?.title}</Text>
													<Text fontSize="smaller">{link?.url}</Text>
												</Flex>
											)) : null
										}
										{(links && links?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
									</Flex>
							}
						</Flex>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	);
};

export default AccountCallHistory;
