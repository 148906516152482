// Chakra imports
// Chakra imports
import { Flex, Spinner, Stat, StatLabel, StatNumber, Icon, useColorModeValue, Text } from "@chakra-ui/react";
import { useNavigate, } from "react-router-dom";
// Custom components
import Card from "../../components/card/Card.js";
// Custom icons
import { AiOutlineEllipsis } from "react-icons/ai";
import { usePillHoverBgColor, useStatisticsPillBorderColor } from '../../theme/globalColorTheme';

import React from "react";

export default function Default(props) {
	const navigate = useNavigate();

	const { launchUrl, startContent, endContent, name, growth, value, loading } = props;
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const whitedarkBg = useColorModeValue("white", "#151117");
	return (
		<Card py="15px" 
			onClick={() => {
				navigate(launchUrl, {
					state: {
					},
				});
			}}
			borderColor={useStatisticsPillBorderColor} borderWidth={'0.5px'}
			_hover={{ cursor: "pointer", bg: usePillHoverBgColor, 'borderWidth': '0.5px', 'borderColor':'red.100'}} 
			bg={whitedarkBg}>
			<Flex my="auto" h="100%" align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
				{startContent}
				<Stat my="auto" ms={startContent ? "18px" : "0px"}>
					<StatLabel
						lineHeight="100%"
						color={"textColorSecondary"}
						fontSize={{
							base: "sm",
						}}>
						{name}
					</StatLabel>
					{loading ? (
						// <Icon h="3rem" w="3rem" as={AiOutlineEllipsis} />
						<Spinner size={'md'} />
					) : (
						<StatNumber
							color={textColor}
							fontSize={{
								base: "2xl",
							}}>
							{value}
						</StatNumber>
					)}
					{growth ? (
						<Flex align="center">
							<Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
								{growth}
							</Text>
							<Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
								since last month
							</Text>
						</Flex>
					) : null}
				</Stat>
				<Flex ms="auto" w="max-content">
					{endContent}
				</Flex>
			</Flex>
		</Card>
	);
}
