// Chakra Imports
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineRobot } from "react-icons/ai";
import {
    Icon,
    Box,
    FormLabel,
    Heading,
    Flex,
    Text,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from "@chakra-ui/react";

// Component Imports
import BasicAIInAction from "./BasicAIInAction";
// import BasicAiLanguageList from "../../../../utils/BasicAILanguageList";
import BasicAiSourceLanguageList from "../../../../utils/BasicAiSourceLanguageList";

// Api Call Imports
import { accessibilityPageEx } from "../../../../services/dynamicPageSvc";
import { translationLanguages } from "../../../../utils/TranslationLanguages";



// https://developers.deepgram.com/docs/models-languages-overview
// Bulgarian: bg
// Catalan: ca
// Chinese (Mandarin, Simplified):zh,zh-CN,zh-Hans
// Chinese (Mandarin, Traditional):zh-TW,zh-Hant
// Czech: cs
// Danish: da, da-DK
// Dutch: nl
// English: en, en-US, , , , 
// Estonian: et
// Finnish: fi
// Flemish: nl-BE
// French: fr, fr-CA
// German: de
// German (Switzerland): de-CH
// Greek: el
// Hindi: hi, hi-Latn
// Hungarian: hu
// Indonesian: id
// Italian: it
// Japanese: ja
// Korean: ko, ko-KR
// Latvian: lv
// Lithuanian: lt
// Malay: ms
// Norwegian: no
// Polish: pl
// Portuguese: pt, pt-BR
// Romanian: ro
// Russian: ru
// Slovak: sk
// Spanish: es, es-419
// Swedish: sv, sv-SE
// Thai: th, th-TH
// Turkish: tr
// Ukrainian: uk
// Vietnamese: vi

const BasicAISettingsComp = props => {
    const navigate = useNavigate();
    const [loading, isLoading] = useState(false);
    const langArr = BasicAiSourceLanguageList;
    const sourceLanguageArr = BasicAiSourceLanguageList;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const launchKey = props.launchKey ? props.launchKey : null;
    const launchCode = props.launchCode ? props.launchCode : null;
    const sessionKey = props.sessionKey ? props.sessionKey : null;

    // Todo:Store Dropdown Data
    const [basicAIFlag, setBasicAIFlag] = useState(false);
    const [basicAITutorialFlag, setBasicAITutorialFlag] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [serviceType, setServiceType] = useState("General");
    const [selectedSettings, setSelectedSettings] = useState({});
    const [sourceLanguage, setSourceLanguage] = useState('English');
    const [targetLanguage, setTargetLanguage] = useState('Hindi');
    const [selectGender, setSelectGender] = useState('No preference');
    const [supportedLangs, setSupportedLangs] = useState([])
    const [preferredLangs, setPreferredLangs] = useState([])
    const [securePhoneSupport, setSecurePhoneSupport] = useState(false)

    const serviceTypes = [{ name: 'General', id: 'G' }]
    const genders = [{ name: 'No preference', id: 'NP' }, { name: 'Male', id: 'M' }, { name: 'Female', id: 'F' }];

    useEffect(() => {
        runPageAccessibility();
    }, []);

    useEffect(() => {
        
        if (!preferredLangs.length && supportedLangs.length > 0) {
            setSourceLanguage(supportedLangs[0]?.name)
            if (supportedLangs[1]) {
                setTargetLanguage(supportedLangs[1]?.name)
            } else {
                setTargetLanguage(supportedLangs[0]?.name)
            }
        }else if(preferredLangs.length){
            setSourceLanguage(preferredLangs[0]?.name)
            if (preferredLangs[1]) {
                setTargetLanguage(preferredLangs[1]?.name)
            } else {
                setTargetLanguage(preferredLangs[0]?.name)
            }
        }else{
            setSourceLanguage(sourceLanguageArr[0]?.name)
            setTargetLanguage(sourceLanguageArr[1]?.name)
        }
    }, [supportedLangs, preferredLangs])

    const runPageAccessibility = () => {
        if (launchKey != null && sessionKey != null) {
            let paramObj = {
                feature: 'bi',
                launchKey: launchKey,
                launchSession: sessionKey,
            };
            accessibilityPageEx(paramObj)
                .then(res => {
                    if (res?.data[0]?.status) {
                        // Load the Page only when True
                        let supported_langs = res?.data[0]?.supported_langs?.split(',')
                        let preferred_langs = res?.data[0]?.preferred_langs?.split(',')
                        
                        supported_langs = translationLanguages.filter(item => supported_langs?.indexOf(item.code) > -1)
                        preferred_langs = translationLanguages.filter(item => preferred_langs?.indexOf(item.code) > -1)
                        setSecurePhoneSupport(res?.data[0]?.secure_phone_support)
                        setPreferredLangs(preferred_langs)
                        setSupportedLangs(supported_langs)
                    } else {
                        // Generate Error -> this feature is not enabled to back to DynamicHome Page
                    }
                })
                .catch(err => {
                    alert(err)
                    isLoading(false);
                });
        }
    };

    useEffect(() => {
        if (selectedValue?.length) {
            renderSelectedValue();
        }
    }, [selectedValue]);

    const renderSelectedValue = () => {
        if (selectedValue === "Source") {
            renderLangugageData();
        }
    };

    useEffect(() => {
        let obj = {
            sourceLangCode: sourceLanguage,
            targetLangCode: targetLanguage,
            serviceTypeCode: serviceType,
            genderCode: selectGender,
        };
        setSelectedSettings(obj);
    }, [sourceLanguage, targetLanguage, serviceType, selectGender]);

    const renderBasicAISelectUI = () => {
        return renderSelectionFormData();
    };

    const renderSelectionFormData = () => {
        return (
            <Box p="2" borderWidth={"1px"} bg="#fff" borderRadius={"10px"} w="100%" >
                <Flex
                    w="100%"
                    mb="4"
                    alignItems={"center"}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    justifyContent={"space-between"}
                    p="2">
                    <FormLabel mb="0px" w="25%" color="gray.800">
                        Source Language{" "}
                    </FormLabel>
                    <Text w="50%" bg="#e2e8f0" borderWidth="1px" p="2" borderRadius={"10px"} mr="3" color="gray.800">
                        {sourceLanguage}
                    </Text>
                    <Button
                        size="md"
                        bg="#e2e8f0"
                        ml="2"
                        color="gray.600"
                        borderRadius={"10px"}
                        onClick={() => {
                            onOpen();
                            setSelectedValue("Source");
                        }}>
                        ...
                    </Button>
                </Flex>
                <Flex
                    w="100%"
                    mb="4"
                    p="2"
                    alignItems={"center"}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    justifyContent={"space-between"}>
                    <FormLabel w="25%" mb="0px" color="gray.800">
                        Target Language{" "}
                    </FormLabel>
                    <Text w="50%" bg="#e2e8f0" borderWidth="1px" p="2" borderRadius={"10px"} mr="3" color="gray.800">
                        {targetLanguage}
                    </Text>
                    <Button
                        size="md"
                        bg="#e2e8f0"
                        ml="2"
                        color="grey"
                        borderRadius={"10px"}
                        onClick={() => {
                            onOpen();
                            setSelectedValue("Target");
                        }}>
                        ...
                    </Button>
                </Flex>
                <Flex
                    w="100%"
                    mb="4"
                    p="2"
                    alignItems={"center"}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    justifyContent={"space-between"}>
                    <FormLabel w="25%" mb="0px" color="gray.800">
                        Service Type{" "}
                    </FormLabel>
                    <Text w="50%" borderWidth="1px" p="2" bg="#e2e8f0" borderRadius={"10px"} mr="3" color="gray.800">
                        {serviceType}
                    </Text>
                    <Button
                        size="md"
                        bg="#e2e8f0"
                        ml="2"
                        color="grey"
                        borderRadius={"10px"}
                        onClick={() => {
                            onOpen();
                            setSelectedValue("Service");
                        }}>
                        ...
                    </Button>
                </Flex>
                <Flex
                    w="100%"
                    mb="4"
                    p="2"
                    alignItems={"center"}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    justifyContent={"space-between"}>
                    <FormLabel w="25%" color="#fff">Select Gender</FormLabel>

                    {renderGenderData()}
                    {/* </Select> */}
                </Flex>
                <Flex justifyContent={"end"}>
                    <Button
                        type="button"
                        colorScheme="green"
                        borderRadius={"10px"}
                        onClick={() => {
                            setBasicAIFlag(true);
                            setBasicAITutorialFlag(true);
                        }}
                        mr="2">
                        <Icon as={AiOutlineRobot} mr={1} />
                        Secure AI Tutorial
                    </Button>

                    <Button
                        type="button"
                        colorScheme="blue"
                        borderRadius={"10px"}
                        onClick={() => {
                            setBasicAIFlag(true);
                        }}
                        mr="2">
                        <Icon as={AiOutlineRobot} mr={1} />
                        Secure AI Interpretation
                    </Button>
                    <Button
                        type="button"
                        colorScheme="red"
                        borderRadius={"10px"}
                        onClick={() => {
                            navigate(-1);
                        }}>
                        Back
                    </Button>
                </Flex>
            </Box>
        );
    };

    // Todo:HOC for all the renders (source, target, service, gender)
    const OptionItem = ({ data, onClick, isSelected, text }) => (
        <Text
            key={data.name}
            value={data.name}
            color="#000"
            w="31%"
            borderWidth="1px"
            p="2"
            borderRadius="10px"
            m="1%"
            onClick={() => onClick(data.name)}
            bg={isSelected ? "green.200" : "#e2e8f0"}
            cursor="pointer"
        >
            {text || data.name}
        </Text>
    );

    const renderOptions = (dataArray, onClickHandler, selectedItem) => {
        return dataArray.map((data, index) => (
            <OptionItem
                data={data}
                key={index}
                onClick={onClickHandler}
                isSelected={data.name === selectedItem}
            />
        ));
    };

    // Render Source Language
    const renderLangugageData = () => {
        if (supportedLangs.length) {
            return renderOptions(supportedLangs, setSourceLanguage, sourceLanguage);
        }
        return renderOptions(sourceLanguageArr, setSourceLanguage, sourceLanguage);
    };

    function renderPreferredLangs(){
        if(preferredLangs.length){
            return renderOptions(preferredLangs, setSourceLanguage, sourceLanguage)
        }
    }

    function renderToPreferredLangs(){
        if (preferredLangs.length) {
            return renderOptions(preferredLangs, setTargetLanguage, targetLanguage);
        }
    }

    // Render Target Language
    const renderToLanguage = () => {
        if (supportedLangs.length) {
            return renderOptions(supportedLangs, setTargetLanguage, targetLanguage);
        }
        return renderOptions(langArr, setTargetLanguage, targetLanguage);
    };

    // Render Service Type
    const renderServiceDropDown = () => {
        return renderOptions(serviceTypes, setServiceType, serviceType);
    };

    // Render Gender Type
    const renderGenderData = () => {
        return renderOptions(genders, setSelectGender, selectGender);
    };

    // Todo:Handle Close
    const handlerCloser = () => {
        onClose();
    };

    return (
        <>
            <Box w={"100%"} h="100%" overflow={"auto"}>
                {launchKey != null && sessionKey != null ? (
                    <>
                        <Flex w="100%" h="100%" flexWrap={"wrap"}>
                            {!basicAIFlag ? (
                                <Flex
                                    w={{
                                        base: "100%",
                                        md: "100%",
                                        lg: "50%",
                                    }}
                                    p="10px"
                                    mx="auto"
                                    alignItems={"center"}
                                    justifyContent={"center"}>
                                    {renderBasicAISelectUI()}
                                </Flex>
                            ) : (
                                <Box w="100%" borderLeftWidth={"0px"}>
                                    <BasicAIInAction
                                        launchKey={launchKey}
                                        launchCode={launchCode}
                                        sessionKey={sessionKey}
                                        selectedSettings={selectedSettings}
                                        basicAITutorialFlag={basicAITutorialFlag}
                                        securePhoneSupport={securePhoneSupport}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </>
                ) : (
                    <Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
                        <Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
                            Hmm....{" "}
                            <Text as="spam" fontWeight={"600"} mr="1">
                                Something is not right!!
                            </Text>
                        </Heading>
                        <Text mt="3" fontSize={"17px"} color="gray.700">
                            Looks like, there is some issues with Human Translation...
                        </Text>
                    </Flex>
                )}
            </Box>
            {/* Drawer Ui */}
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    handlerCloser();
                }}
                size="md">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        Select {selectedValue} {selectedValue === "Service" ? "Type" : "Language"}{" "}
                    </DrawerHeader>
                    <DrawerBody>

                        <Tabs isFitted variant='enclosed-colored'>
                            <TabList>
                                {preferredLangs.length? <Tab>Preferred Languages</Tab> : null}
                                <Tab>All Languages</Tab>
                            </TabList>
                            <TabPanels>
                                {preferredLangs.length? <TabPanel>
                                    <Flex flexWrap={"wrap"}>
                                        {selectedValue === "Source"
                                            ? renderPreferredLangs()
                                            : selectedValue === "Target"
                                                ? renderToPreferredLangs()
                                                : renderServiceDropDown()}
                                    </Flex>
                                </TabPanel> : null}
                                <TabPanel>
                                    <Flex flexWrap={"wrap"}>
                                        {selectedValue === "Source"
                                            ? renderLangugageData()
                                            : selectedValue === "Target"
                                                ? renderToLanguage()
                                                : renderServiceDropDown()}
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            variant="outline"
                            size="sm"
                            borderRadius="10px"
                            mr={3}
                            onClick={() => {
                                handlerCloser();
                            }}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="green"
                            size="sm"
                            borderRadius="10px"
                            onClick={() => {
                                handlerCloser();
                            }}>
                            Save
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default BasicAISettingsComp;
