import React from "react";
import { BiUserCircle, BiMoney, BiDollarCircle } from "react-icons/bi";
import { Icon, layout } from "@chakra-ui/react";
import {
	AiOutlineSetting,
	AiOutlineDashboard,
	AiOutlineMobile,
	AiOutlineSelect,
	AiOutlineLink,
	AiOutlineCar,
	AiOutlineGift,
	AiOutlineUserAdd,
	AiOutlineUserSwitch,
	AiOutlineSecurityScan,
	AiOutlineOrderedList,
	AiFillQuestionCircle,
	AiOutlineUsergroupAdd,
	AiOutlineGlobal,
} from "react-icons/ai";

// Business Componenr Imports
import BusinessDashboard from "../komponents/business/dashboard/BusinessDashboard";
import AssociateListing from "../komponents/business/associates/AssociatesListing";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";
import BusinessSettings from "../komponents/business/settings/BusinessSettings";
import ProjectListing from "../komponents/documentai/ProjectListing";
import ProjectContent from "../komponents/documentai/ProjectContent";
import ContentWorkflowMgmt from "../komponents/documentai/ContentWorkflowMgmt";
import ContentWorkflowResults from "../komponents/documentai/ContentWorkflowResult";
import DevicesList from "../komponents/business/devices/DeviceList";
import Configuration from "../komponents/business/configuration/Configuration";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkCustomers from "../komponents/business/customers/LinkCustomers";
import LinkCustomerCalls from "../komponents/business/customers/LinkCustomerCalls";
import LinkCustomerManage from "../komponents/business/customers/LinkCustomerManage";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";
import CardListing from "../komponents/business/workspace/Cards/CardListing";
import CardManager from "../komponents/business/workspace/Cards/CardManager";

// Dashboard
import GettingStartedHome from "../komponents/business/dashboard/GettingStarted";

// Billing
import ClientBillingHome from "../komponents/business/billing/ClientBillingHome";
import TranslateSystem from "../komponents/DynamicLink/components/TranslateSystem";
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import BusinessInfo from "../komponents/business/settings/BusinessInfo";
import ConfigurationSettings from "../komponents/business/configuration/ConfigurationSettings";
// import LanguageAssociateList from "../komponents/business/LangAssociates/LanguageAssociateList";

// Accounts Management
import AccountsListing from "../komponents/business/accounts/AccountsListing";
import AccountManage from "../komponents/business/accounts/accountmanagement/AccountManage";
import AccountsLocations from "../komponents/business/accounts/locations/AccountLocations";
import AccountsEvents from "../komponents/business/accounts/AccountEvents";
import LinkTranscript from "../komponents/business/workspace/Links/LinkTranscript";
import ApplicationSettings from "../komponents/common/ApplicationSettings";

const businessRoutes = [
	{
		name: "Dashboard",
		path: "/business",
		icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Statistics",
				layout: "/business",
				path: "/stats",
				component: <BusinessDashboard />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
			},
			{
				name: "Getting Started",
				layout: "/business",
				path: "/gettingstarted",
				component: <GettingStartedHome />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineCar} width="20px" height="20px" />,
			},
			{
				name: "Link Transcripts",
				layout: "/business",
				path: "/linkmananger/transcripts",
				component: <LinkTranscript />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Lingolet Products",
		path: "/business",
		icon: <Icon as={AiOutlineSelect} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "LingoLink",
				layout: "/business",
				path: "/linklisting",
				component: <LinkListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "LingoLink",
				layout: "/business",
				path: "/linkmanager",
				component: <LinkManager />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Link Configuration",
				layout: "/business",
				path: "/linkmananger/configuration",
				component: <LinkConfiguration />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
			},
			{
				name: "LingoLinks",
				layout: "/business",
				path: "/linkmananger/sessions",
				component: <LinkSession />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
			},
			{
				name: "LingoLinks",
				layout: "/business",
				path: "/linkmananger/calls",
				component: <LinkCalls />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSecurityScan} width="20px" height="20px" />,
			},
			{
				name: "LingoCard",
				layout: "/business",
				path: "/cardlisting",
				component: <CardListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineGift} width="20px" height="20px" />,
			},
			{
				name: "LingoCard",
				layout: "/business",
				path: "/cardlisting/cardmanager",
				component: <CardManager />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineGift} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Accounts",
		path: "/business",
		icon: <Icon as={AiOutlineUserSwitch} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Management",
				layout: "/business",
				path: "/accountmanagement",
				component: <AccountsListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineUsergroupAdd} width="20px" height="20px" />,
			},
			{
				name: "Locations",
				layout: "/business",
				path: "/accountlocations",
				component: <AccountsLocations />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineGlobal} width="20px" height="20px" />,
			},
			{
				name: "Events",
				layout: "/business",
				path: "/accountevents",
				component: <AccountsEvents />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineUsergroupAdd} width="20px" height="20px" />,
			},
			{
				name: "Manage Accounts",
				layout: "/business",
				path: "/accountmanagement/manage",
				component: <AccountManage />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			// {
			// 	name: "Link Customer Management",
			// 	layout: "/business",
			// 	path: "/linkcustomer/manage",
			// 	component: <LinkCustomerManage />,
			// 	secondary: true,
			// 	showToMenu: false,
			// 	icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			// },
		],
	},
	// {
	// 	name: "Subscribers",
	// 	path: "/business",
	// 	icon: <Icon as={AiOutlineUserSwitch} width="25px" height="25px" />,
	// 	collapse: true,
	// 	items: [
	// 		{
	// 			name: "Listing",
	// 			layout: "/business",
	// 			path: "/listing",
	// 			component: <LinkCustomers />,
	// 			secondary: true,
	// 			showToMenu: true,
	// 			icon: <Icon as={AiOutlineUsergroupAdd} width="20px" height="20px" />,
	// 		},
	// 		{
	// 			name: "Link Customer Calls",
	// 			layout: "/business",
	// 			path: "/linkcustomer/calls",
	// 			component: <LinkCustomerCalls />,
	// 			secondary: true,
	// 			showToMenu: false,
	// 			icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
	// 		},
	// 		{
	// 			name: "Link Customer Management",
	// 			layout: "/business",
	// 			path: "/linkcustomer/manage",
	// 			component: <LinkCustomerManage />,
	// 			secondary: true,
	// 			showToMenu: false,
	// 			icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
	// 		},
	// 	],
	// },
	// {
	// 	name: "Language Associates",
	// 	path: "/business",
	// 	icon: <Icon as={AiOutlineUserAdd} width="25px" height="25px" />,
	// 	collapse: true,
	// 	items: [
	// 		{
	// 			name: "Associate Listing",
	// 			layout: "/business",
	// 			path: "/associateListing",
	// 			component: <LanguageAssociateList />,
	// 			secondary: true,
	// 			showToMenu: true,
	// 			icon: <Icon as={AiOutlineOrderedList} width="20px" height="20px" />,
	// 		},
	// 	],
	// },
	{
		name: "Document AI",
		path: "/business",
		icon: <Icon as={AiOutlineSelect} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Projects",
				layout: "/business",
				path: "/projects",
				component: <ProjectListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Content",
				layout: "/business",
				path: "/project/content",
				component: <ProjectContent />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Workflow",
				layout: "/business",
				path: "/project/content/workflow",
				component: <ContentWorkflowMgmt />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Workflow",
				layout: "/business",
				path: "/project/content/workflow_result",
				component: <ContentWorkflowResults />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Devices",
		path: "/business",
		icon: <Icon as={AiOutlineMobile} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Devices",
				layout: "/business",
				path: "/devices",
				component: <DevicesList />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineMobile} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Team Members",
		path: "/business",
		icon: <Icon as={BiUserCircle} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Colleague",
				layout: "/business",
				path: "/associates",
				component: <AssociateListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={BiUserCircle} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Billing",
		path: "/business",
		icon: <Icon as={BiDollarCircle} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Client Billing",
				layout: "/business",
				path: "/clientbilling",
				component: <ClientBillingHome />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={BiMoney} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Settings",
		path: "/business",
		icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Profile",
				layout: "/business",
				path: "/profile",
				component: <BusinessProfile />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			},
			{
				name: "Business Info",
				layout: "/business",
				path: "/businessinfo",
				// component: <BusinessSettings />,
				component: <BusinessInfo />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			},
			{
				name:"Application Settings",
				layout:'/business',
				path:"/applicationsetting",
				component:<ApplicationSettings/> ,
				secondary:true,
				showToMenu:true,
				icon:<Icon as={AiOutlineSetting} width="20px" height="20px" />,
			}
			// {
			// 	name: "Configuration",
			// 	layout: "/business",
			// 	path: "/configuration",
			// 	component: <ConfigurationSettings />,
			// 	secondary: true,
			// 	showToMenu: true,
			// 	icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			// },
		],
	},
	{
		name: "Help",
		path: "/business",
		icon: <Icon as={AiFillQuestionCircle} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Get Help",
				layout: "/business",
				path: "/help",
				component: <Helper />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiFillQuestionCircle} width="20px" height="20px" />,
			},
			{
				name: "Compliance",
				layout: "/business",
				path: "/trust",
				component: <PlatformTrust />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			},
		],
	},
];

export default businessRoutes;
