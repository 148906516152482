import {
	Alert, AlertIcon, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Heading, Icon, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Spacer, Spinner, Switch, Text, Textarea, Tooltip, useDisclosure, useToast, Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Checkbox,
	InputGroup,
	InputLeftElement,
	Select,
	Input,
	FormHelperText,
	useColorModeValue,
	background,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineCheckCircle, AiOutlineStop, AiOutlineSync } from 'react-icons/ai'
import Multiselect from 'multiselect-react-dropdown';
import moment from "moment";

import { DRAWER, lingoCardFilters } from './constants';
import { useNavigate } from 'react-router-dom';
import { PhoneIcon } from '@chakra-ui/icons';
import { useBlueButton, useGreenBg, useRedButton, useTextColor, useWhiteDarkBg, useYellowButton } from '../../../theme/globalColorTheme';
import { toastFunctionToaster } from '../../../utils/toastFunction';
import CardAccessCode from '../../subscriber/components/CardAccessCode';
import { countryCodes } from '../../../utils/countryCodes';
import Pagination from '../../../components/pagination/Pagination';
import { checkAdminLingoCardEnabled, commonDetailsAdminLingoCard, createAdminLingoCard, listAdminLingoCards, manageAdminLingoCard, refreshAdminLingoCard, shareAdminLingoCard, shareAdminLingoCardSms, updateAdminLingoCard } from '../../../services/adminServices';
import { fetchLocationInfo } from '../../../services/accountServices';
import TagsInput from 'react-tagsinput';
import Card from '../../../components/card/Card';


const CardListing = () => {
	const [cardData, setCardData] = useState([])
	const [cardUuid, setCardUuid] = useState(null)
	const [activeDays, setActiveDays] = useState(50);
	const [callTime, setCallTime] = useState(50);
	const [voiceCallValue, setVoiceCallValue] = useState(5);
	const [videoCallValue, setVideoCallValue] = useState(5);
	const [lingoCardTitle, setLingoCardTitle] = useState("");
	const [sourceLanguagesData, setSourceLanguagesData] = useState([]);
	const [targetLanguagesData, setTargetLanguagesData] = useState([]);
	const [saveCardLoader, setSaveCardLoader] = useState(false);
	const [titleErrorFlag, setTitlErrorFlag] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [accountUuid, setAccountUuid] = useState(null)
	const [locationUuid, setLocationUuid] = useState(null)
	const [recordsPerPage, setRecordsPerPage] = useState(10)
	const [currPage, setCurrPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [totalrecords, setTotalRecords] = useState(0)
	const [cardFilter, setCardFilter] = useState(lingoCardFilters.ACTIVE)
	const [drawerType, setDrawerType] = useState('')
	const [toEmails, setToEmails] = useState([])
	const [cardShareMessage, setcardShareMessage] = useState(null)
	const [loading, setLoading] = useState(false)
	const [isLingoCardEnabled, setIsLingoCardEnabled] = useState(false)
	const navigate = useNavigate()
	const [commonDetails, setCommaonDetails] = useState({})
	const [activeCardDetails, setActiveCardDetails] = useState(null)
	const [sourceLangDisabled, setSourceLangDisabled] = useState(false)
	const [targetLangDisabled, setTargetLangDisabled] = useState(false)
	const [isAddUpdateDisabled, setIsAddUpdateDisabled] = useState(true)
	const [selectedCountryCode, setSelectedCountryCode] = useState()
	const [languagesList, setLanguagesList] = useState([])
	const [cardInitialDetails, setCardInitialDetails] = useState({})
	const boostlingoKey = process.env.REACT_APP_BOOSTLINGO_KEY;
	const [phoneNumber, setPhoneNumber] = useState('')
	const [smsSharingLoading, setSmsSharingLoading] = useState('')
	const [errors, setErrors] = useState({})
	const blackWhiteColor = useTextColor()
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const whiteDarkBg = useWhiteDarkBg()
	const cardBg = useColorModeValue("white", "#151117")


	const btnRef = useRef();

	const toast = useToast()

	const redBg = useRedButton()
	const greenBg = useGreenBg()
	const textColor = useTextColor()
	const yellowBg = useYellowButton()
	const blueButtonBg = useBlueButton()
	const {
		isOpen: isDrawerOpen,
		onOpen: onDrawerOpen,
		onClose: onDrawerClose,
	} = useDisclosure();

	const {
		isOpen: isModalOpen,
		onOpen: onModalOpen,
		onClose: onModalClose,
	} = useDisclosure();

	useEffect(() => {
		collectAccountLocationInfo();
		fetchCommonDetails()
	}, []);

	useEffect(() => {
		checkIfLingoCardEnabled()
	}, [accountUuid, locationUuid])

	useEffect(() => {
		if (isLingoCardEnabled) {
			fetchLingoCardsData()
		}
	}, [accountUuid, locationUuid, recordsPerPage, currPage, cardFilter, isLingoCardEnabled])

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3-us-west-2.amazonaws.com/connect.boostlingo.com/sdk/boostlingo-js/1.0.4/dist/boostlingo.min.js";
		script.async = true;
		document.body.appendChild(script);
		script.onload = () => {
			loadBl();
		};
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (cardUuid) {
			setIsAddUpdateDisabled(checkIfValuesUpdated())
		} else {
			lingoCardTitle?.length && (sourceLangDisabled || sourceLanguagesData?.length) && (targetLangDisabled || targetLanguagesData?.length) ? setIsAddUpdateDisabled(false) : setIsAddUpdateDisabled(true)
		}
	}, [lingoCardTitle, sourceLangDisabled, sourceLanguagesData, targetLangDisabled, targetLanguagesData, activeDays, callTime, voiceCallValue, videoCallValue, cardUuid])

	const loadBl = async () => {
		let boostInfo = JSON.parse(boostlingoKey);
		var blJS = new window.BoostLingo(boostInfo.token);
		const resp = await blJS.getCallDictionaries();
		setLanguagesList(resp?.languages)

		return blJS;
	};

	const checkIfValuesUpdated = () => {
		const newValues = {
			title: lingoCardTitle,
			active_days: activeDays,
			minutes: callTime,
			voice_calls: voiceCallValue,
			video_calls: videoCallValue,
			sourceLangs: sourceLanguagesData,
			targetLangs: targetLanguagesData,
			isSourceLangDisabled: sourceLangDisabled,
			istargetLangDisabled: targetLangDisabled
		}

		return JSON.stringify(newValues) === JSON.stringify(cardInitialDetails)
	}


	const checkIfLingoCardEnabled = () => {
		if (!accountUuid) return
		const reqBody = {
			link_account_uuid: accountUuid,
			link_location_uuid: locationUuid
		}
		checkAdminLingoCardEnabled(reqBody).then(resp => {
			setIsLingoCardEnabled(resp?.data[0]?.lingocard_enable)
			if (!resp?.data[0]?.lingocard_enable) {
				toast(toastFunctionToaster(resp.message, "error"));
			}
		}).catch(err => {
			toast(toastFunctionToaster(err.message, "error"));
		})
	}

	const fetchCommonDetails = () => {
		commonDetailsAdminLingoCard().then(resp => {
			if (resp.result) {
				setCommaonDetails(resp.data[0])
			}
		}).catch(err => {

		})
	}

	const fetchLingoCardsData = () => {
		if (accountUuid) {
			setLoading(true)
			let paramsObj = {
				"link_account_uuid": accountUuid,
				"link_location_uuid": locationUuid,
				"key": cardFilter,
				"records_per_page": recordsPerPage,
				"page_id": currPage
			}

			listAdminLingoCards(paramsObj).then(resp => {
				if (resp?.result) {
					setCardData(resp?.data[0].records)
					setTotalRecords(resp?.data[0].total_records)
					setTotalPages(resp?.data[0].total_pages)
				} else {
					setCardData([])
					setTotalRecords(0)
					setTotalPages(0)
				}
				setLoading(false)
			})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"))
					setCardData([])
					setTotalRecords(0)
					setTotalPages(0)
					setLoading(false)
				})
		}
	}

	const collectAccountLocationInfo = () => {
		setLoading(true);
		let paramObj = {
			key: 'subscriber',
		};
		fetchLocationInfo(paramObj)
			.then(res => {
				setLoading(false);
				if (res?.data[0]?.status) {
					setLoading(false);
					setAccountUuid(res?.data[0]?.uuid || "")
					setLocationUuid(res?.data[0]?.locUuid || "")
				} else {
					setLoading(false);
					setAccountUuid("")
					setLocationUuid("")
				}
			})
			.catch(err => {
				setLoading(false);
				setAccountUuid("")
				setLocationUuid("")
			});
	};

	const closeDrawer = () => {
		onDrawerClose();
		resetDetails();
	};

	const resetDetails = () => {
		onDrawerClose();
		setCardUuid(null);
		setActiveDays(30);
		setCallTime(30);
		setVoiceCallValue(5);
		setVideoCallValue(5);
		setLingoCardTitle(null);
		setSourceLanguagesData([]);
		setTargetLanguagesData([]);
		setSaveCardLoader(false);
		setSourceLangDisabled(false)
		setTargetLangDisabled(false)
		setIsAddUpdateDisabled(false)
		setCardInitialDetails([])
		setcardShareMessage('')
		setPhoneNumber('')
		setToEmails([])
		setErrors()
		setSmsSharingLoading('')
	};

	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};
	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	const handleSourceSelect = selectedList => {
		if (selectedList.length) {
			selectedList.length > 10 && selectedList.length--
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setSourceLanguagesData(selectedList);
		}
		else {
			setSourceLanguagesData([])
		}
	};

	const handleTargetSelect = selectedList => {
		if (selectedList.length) {
			selectedList.length > 10 && selectedList.length--
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setTargetLanguagesData(selectedList);
		} else {
			setTargetLanguagesData([])
		}
	};

	const getLanguageList = selectedArr => {
		let langs = [];
		if (selectedArr.length > 0) {
			selectedArr.map((item, key) => {
				langs.push(item["code"]);
			});
		}
		if (langs.length > 0) {
			return langs.join();
		}
		return "";
	};

	const addUpdateCardDetails = () => {
		if (
			lingoCardTitle?.length > 0 &&
			(sourceLangDisabled || sourceLanguagesData?.length) &&
			(targetLangDisabled || targetLanguagesData?.length)
		) {
			setTitlErrorFlag(false);
			setSaveCardLoader(true);
			let obj = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				lingo_card_uuid: cardUuid ? cardUuid : null,
				lingo_card_title: lingoCardTitle,
				start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
				active_days: activeDays,
				videoCount: videoCallValue,
				voiceCount: voiceCallValue,
				source_language: getLanguageList(sourceLanguagesData), // JSON.stringify(sourceLanguagesData),
				target_language: getLanguageList(targetLanguagesData), //JSON.stringify(targetLanguagesData),
				callTime: callTime,
			};
			if (cardUuid) {
				updateAdminLingoCard(obj).then(res => {
					setSaveCardLoader(false)
					if (res?.result) {
						resetDetails()
						toast(toastFunctionToaster(res.message, "success"));
						fetchLingoCardsData()
					} else {
						toast(toastFunctionToaster(res.message, 'error'))
					}
				})
					.catch(err => {
						setSaveCardLoader(false)
					})
			} else {
				createAdminLingoCard(obj).then(res => {
					setSaveCardLoader(false)
					if (res?.result) {
						resetDetails();
						toast(toastFunctionToaster(res.message, "success"));
						fetchLingoCardsData()
					} else {
						toast(toastFunctionToaster(res.message, 'error'))
					}
				})
					.catch(err => {
						setSaveCardLoader(false)
						toast(toastFunctionToaster(err.message, "error"));
					})
			}
		} else {
			setTitlErrorFlag(true);
		}
	};

	const updateRowDetails = data => {
		onDrawerOpen();
		setActiveDays(data?.active_days);
		setCallTime(data?.card_minutes);
		setVoiceCallValue(data?.card_voice_calls);
		setVideoCallValue(data?.card_video_calls);
		setLingoCardTitle(data?.lingo_card_title);

		setCardInitialDetails({
			title: data?.lingo_card_title,
			active_days: data?.active_days,
			minutes: data?.card_minutes,
			voice_calls: data?.card_voice_calls,
			video_calls: data?.card_video_calls,
			sourceLangs: [],
			targetLangs: [],
			isSourceLangDisabled: false,
			istargetLangDisabled: false
		})

		if (data?.card_source_langs === '') {
			setSourceLangDisabled(true)
			setSourceLanguagesData([])
			setCardInitialDetails(details => { return { ...details, isSourceLangDisabled: true } })
		} else {
			const items = returnSelectedData(
				data?.card_source_langs
			)
			setSourceLanguagesData(items);
			setCardInitialDetails(details => { return { ...details, sourceLangs: items, isSourceLangDisabled: false } })

		}

		if (data?.card_target_langs === '') {
			setTargetLangDisabled(true)
			setSourceLanguagesData([])
			setCardInitialDetails(details => { return { ...details, istargetLangDisabled: true } })
		} else {
			const items = returnSelectedData(
				data?.card_target_langs?.length > 0 ? data?.card_target_langs : []
			)
			setTargetLanguagesData(items);
			setCardInitialDetails(details => { return { ...details, targetLangs: items, istargetLangDisabled: false } })
		}
	};

	const returnSelectedData = data => {
		const dataArr = data?.split(",");
		return languagesList.filter(item =>
			dataArr.includes(item.code)
		);
	};

	const renderLanguageListDetails = langs => {
		let langData = langs.split(",");
		const filteredData = languagesList.filter(item =>
			langData.includes(item.code)
		);
		if (filteredData.length > 0) {
			return filteredData?.map((item, index) => {
				return (
					<Text
						m={1}
						p={1}
						fontSize={"xs"}
						bg={"blue.100"}
						rounded={"md"}
						color="black"
					>
						{item?.name}
					</Text>
				);
			});
		} else {
			return <Text
				m={1}
				p={1}
				fontWeight="bold"
			>
				All
			</Text>
		}
	};

	const handleCardManage = () => {
		if (activeCardDetails) {
			let obj = {
				"link_account_uuid": accountUuid,
				"link_location_uuid": locationUuid,
				"lingo_card_uuid": activeCardDetails?.lingo_card_uuid
			}
			manageAdminLingoCard(obj).then(resp => {
				if (resp.result) {
					fetchLingoCardsData()
					toast(toastFunctionToaster(resp.message, "success"));
				} else {
					toast(toastFunctionToaster(resp.message, "error"));
				}

			})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
				})
			setActiveCardDetails(null)
			onModalClose()
		}
	}

	const handleShareCard = () => {
		const params = {
			"link_account_uuid": accountUuid,
			"link_location_uuid": locationUuid,
			"lingo_card_uuid": cardUuid,
			"target_emails": toEmails?.join(","),
			"card_message": cardShareMessage
		}
		setSaveCardLoader(true)
		shareAdminLingoCard(params).then(resp => {
			setSaveCardLoader(false)
			if (resp?.data[0]?.status) {
				setToEmails([])
				setcardShareMessage('');
				onDrawerClose()
				toast(toastFunctionToaster(resp.message, "success"));
			} else {
				toast(toastFunctionToaster(resp.message, "error"));
			}
		}).catch(err => {
			setSaveCardLoader(false)
			toast(toastFunctionToaster(err.message, "error"));
		})
	}

	const handleRefreshCard = (cardUuid) => {
		const params = {
			"link_account_uuid": accountUuid,
			"link_location_uuid": locationUuid,
			"lingo_card_uuid": cardUuid
		}
		setSaveCardLoader(true)
		refreshAdminLingoCard(params).then(resp => {
			setSaveCardLoader(false)
			if (resp?.result) {
				fetchLingoCardsData()
				toast(toastFunctionToaster(resp.message, "success"))
			} else {
				toast(toastFunctionToaster(resp.message, "error"));
			}
		}).catch(err => {
			setSaveCardLoader(false)
			toast(toastFunctionToaster("Failed to refresh LingoCard", "error"))
		})

	}

	const getDaysHoursMinSec = (start, end) => {
		const startTime = moment(start);
		const endTime = moment(end);

		const duration = moment.duration(endTime.diff(startTime));

		// Extract days, hours, minutes, and seconds
		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();
		return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
	}

	const handleSetSourceLanguageAll = () => {
		setSourceLanguagesData([])
		setSourceLangDisabled(prevState => !prevState)
	}

	const handleSetTargetLanguageAll = () => {
		setTargetLanguagesData([])
		setTargetLangDisabled(prevState => !prevState)
	}

	function handleSendSms() {
		if (validatePhoneNumber()) {
			setErrors(errors => ({ ...errors, invalidNumber: false }))
			const paramObj = {
				"link_account_uuid": accountUuid,
				"link_location_uuid": locationUuid,
				"to": selectedCountryCode + phoneNumber,
				'message': cardShareMessage,
				"lingo_card_uuid": activeCardDetails.lingo_card_uuid
			}
			setSmsSharingLoading(activeCardDetails.lingo_card_uuid)
			shareAdminLingoCardSms(paramObj).then(resp => {
				if (resp.data[0]?.status) {
					setcardShareMessage('')
					setPhoneNumber('')
					closeDrawer()
					toast(toastFunctionToaster("SMS sent successfully", "success"))
				} else {
					toast(toastFunctionToaster("Failed to send SMS", "error"))
				}
				setSmsSharingLoading('')
			}).catch(err => {
				toast(toastFunctionToaster("Failed to send SMS", "error"))
				setSmsSharingLoading('')
			})
		} else {
			setErrors(errors => ({ ...errors, invalidNumber: true }))
		}
	}

	function validatePhoneNumber() {
		const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;
		return phoneNumberRegex.test(selectedCountryCode + phoneNumber);
	}

	async function detectCountryCode() {
		try {
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
			setSelectedCountryCode(data.country_calling_code)
		} catch (error) {
			setSelectedCountryCode()
		}
	}
	const renderCardData =
		cardData !== null && cardData?.length > 0
			? cardData?.map((item, index) => (
				<Card
					key={index}
					p={2}
					w={"100%"}
					mt={2}
					rounded={"md"}
					borderWidth={"0.05px"}
					borderColor={item?.card_active ? "green.200" : "red.200"}>
					<Flex w={"100%"} direction={"column"}>
						<Flex w={"100%"} p={1} align={"center"} flexWrap="wrap" gap="2">
							<Flex w="full" justifyContent="space-between">
								<Text rounded={"md"} bg={item?.card_active ? "green.200" : "red.200"} p={2} minW={"150px"} maxW={"150px"} color="black">
									{item?.card_dead ? (
										<Icon as={AiOutlineStop} color={"red.600"} ml={1} mr={1} mt={1} />
									) : null}
									{item?.lingo_card_title}
								</Text>
								{!item?.card_dead && <Flex>
									<Button
										ml={1}
										_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={pillButtonBg}
										onClick={() => {
											handleRefreshCard(item?.lingo_card_uuid)
										}}
										isLoading={saveCardLoader}
										size={"sm"}
									>
										Refresh
									</Button>
									<Button
										ml={1}
										_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={pillButtonBg}
										onClick={() => {
											navigate('/account/lingocard/usage', {
												state: {
													"link_account_uuid": accountUuid,
													"link_location_uuid": locationUuid,
													"lingo_card_uuid": item?.lingo_card_uuid
												}
											})
										}}
										size={"sm"}
									>
										Usage
									</Button>
									<Button
										ml={1}
										_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={pillButtonBg}
										onClick={() => {
											setCardUuid(
												item?.lingo_card_uuid
											);
											setDrawerType(DRAWER.ADDUPDATECARD)
											updateRowDetails(item);
										}}
										size={"sm"}
									>
										Edit
									</Button>
									{item?.card_active &&
										<Button
											ml={1}
											_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
											bg={pillButtonBg}
											onClick={() => {
												setCardUuid(
													item?.lingo_card_uuid
												);
												setDrawerType(DRAWER.SHARECARD)
												onDrawerOpen()
											}}
											size={"sm"}
										>
											Share
										</Button>
									}
									<Button ml={1}
										_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={pillButtonBg}
										onClick={() => {
											setDrawerType(DRAWER.CARD_DETAILS)
											setActiveCardDetails(item)
											onDrawerOpen()
										}}
										size={"sm"}>
										View card
									</Button>
									<Button ml={1}
										_hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={pillButtonBg}
										onClick={() => {
											setDrawerType(DRAWER.SHARESMS)
											setActiveCardDetails(item)
											detectCountryCode()
											// handleSendSms(item?.lingo_card_uuid)
											onDrawerOpen()
										}}
										size={"sm"}>
										Share on sms
									</Button>
								</Flex>}
							</Flex>
							<Flex w={"100%"} direction={"column"}>
								<Flex w={"100%"}>
									<Text>
										Start Time (UTC):{" "}
										<Text as="spam" fontWeight={"600"}>
											{moment(item?.card_start_time).format("MMM DD,YYYY,HH:mm a")}
										</Text>
									</Text>
									<Text ml={2}>
										End Time (UTC): {moment(item?.card_end_time).format("MMM DD,YYYY,HH:mm a")}
									</Text>
								</Flex>
								<Flex w={"100%"}>
									<Text>
										Call Time: <b>{item?.card_minutes} Minutes</b>

									</Text>
								</Flex>
								<Flex w={"100%"} align={"center"} flexWrap="wrap" gap={2}>
									<Flex w={"100%"} align={"center"}>
										<Flex alignItems="flex-start" w="22%">
											<Text>Source Language: </Text>
										</Flex>
										<Flex w="80%" flexWrap="wrap" gap={1}>
											{renderLanguageListDetails(
												item?.card_source_langs
											)}
										</Flex>
									</Flex>
									<Flex w={"100%"} align={"center"} mt={2} borderTop="1px" borderColor="gray.200">
										<Flex alignItems="flex-start" w="22%">
											<Text>Target Language: </Text>
										</Flex>
										<Flex w="80%" flexWrap="wrap" gap={1}>
											{renderLanguageListDetails(
												item?.card_target_langs
											)}
										</Flex>
									</Flex>


								</Flex>
							</Flex>
							<Flex w="100%" ml={1}>
								<Flex w={"100%"} alignItems="center" justifyContent="space-between">
									{
										item?.card_code && !item?.card_dead && <CardAccessCode code={item.card_code} />
									}
									{!item?.card_dead && <Button _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
										bg={redBg}
										onClick={() => {
											setActiveCardDetails(item)
											onModalOpen()
										}}
										size={"sm"}>Disable Card Forever</Button>}


								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Card>
			))
			: <Alert status="warning" mt="4">
				<AlertIcon />
				<Text>You dont have any {cardFilter} LingoCard </Text>
			</Alert>;

	if (!isLingoCardEnabled) {
		return (
			<Box direction="column" pt={{ sm: "125px", lg: "80px" }} minH="30vh">
				<Flex p="3" alignItems="center" borderBottomWidth="1px" justifyContent={"space-between"}>
					<Alert status="warning" mt="4">
						<AlertIcon />
						<Text>LingoCard is not enabled!</Text>
					</Alert>
				</Flex>
			</Box>
		)
	}


	return (
		<Box direction="column" pt={{ sm: "125px", lg: "80px" }} minH="30vh">
			<Card w={'100%'} rounded={'md'} p={1}>
				<Flex p="2" alignItems="center" justifyContent={"space-between"}>
					<Text fontWeight={"500"} fontSize={"18px"} _firstLetter={{ textTransform: 'capitalize' }}>
						{cardFilter} LingoCard {totalrecords}
					</Text>
					<Spacer />
					<Flex w={"220px"} align={"center"}>
						<Box
							p={1}
							align={"center"}
							w={"100px"}
							_hover={{ cursor: "pointer" }}
							roundedLeft={"lg"}
							bg={cardFilter === lingoCardFilters.ACTIVE ? 'green.600' : greenBg}
							color={cardFilter === lingoCardFilters.ACTIVE ? 'white' : 'inherit'}
							size="sm"
							onClick={() => { setCardFilter(lingoCardFilters.ACTIVE) }}>
							{cardFilter === lingoCardFilters.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Active
						</Box>
						<Box
							p={1}
							bg={cardFilter === lingoCardFilters.DISABLE ? 'red.600' : redBg}
							color={cardFilter === lingoCardFilters.DISABLE ? 'white' : 'inherit'}
							align={"center"}
							w={"100px"}
							size="sm"
							_hover={{ cursor: "pointer" }}
							roundedRight={"lg"}
							onClick={() => { setCardFilter(lingoCardFilters.DISABLE) }}>
							{cardFilter === lingoCardFilters.DISABLE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
							Closed
						</Box>
					</Flex>
					<Button
						bg={yellowBg}
						size="sm"
						rounded={"lg"}
						onClick={() => {
							fetchLingoCardsData()
						}}
						color={textColor}
						_hover={{ color: 'black' }}
					>
						<Icon as={AiOutlineSync} />
					</Button>
					<Button
						ml={1}
						colorScheme="blue"
						rounded={"lg"}
						size="sm"
						onClick={() => {
							onDrawerOpen();
							setDrawerType(DRAWER.ADDUPDATECARD)
						}}>
						<Text>Create New Card</Text>
					</Button>
				</Flex>
			</Card>
			{loading ? (
				<Flex mt={1} alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" />
				</Flex>
			) :
				<Card mt={1} w={'100%'} h='calc(100vh - 245px)' overflowY='hidden' p={2}>
					<Flex h="full" overflowY="auto" className="custom-scrollbar" bg={cardBg} borderRadius="md" p={2}>
						<Flex w={"100%"} direction={"column"} h={'99%'}>
							{renderCardData}
						</Flex>
					</Flex>
				</Card>
			}

			<Drawer
				size="md"
				isOpen={isDrawerOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				{
					drawerType === DRAWER.ADDUPDATECARD && (
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader borderBottomWidth={"1px"}>

								<>{cardUuid ? "Udpate" : "Create New"} Card</>

							</DrawerHeader>
							<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">
								<Flex w={"100%"} direction={"column"}>
									<Input
										mb="0"
										me="30px"
										id="lingoCardTitle"
										label="LingoCard Title"
										value={lingoCardTitle}
										name="lingoCardTitle"
										color={blackWhiteColor}
										required="true"
										borderRadius="5px"
										onChange={e => {
											setLingoCardTitle(e.target.value);
										}}
										placeholder="Enter LingoCard Title"
										w="100%"
										maxlength={100}
									/>
									<Flex alignItems={"center"} justifyContent={"space-between"}>
										{titleErrorFlag && lingoCardTitle?.length <= 0 ? (
											<Text color="red.500" fontSize={"sm"}>
												Card Title is required
											</Text>
										) : null}
										<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
											{lingoCardTitle?.length}/100
										</Text>
									</Flex>
									{/* Todo:Active Daye */}
									<FormLabel mt="3" ml="2" required>
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Active Days
										</Text>
										<Flex
											mt={"10px"}
											h={"100px"}
											w={"100%"}
											rounded={"md"}
											borderWidth={"0.5px"}
											borderColor={"gray.200"}>
											<Slider
												aria-label="slider-ex-1"
												colorScheme="teal"
												min={0}
												max={365}
												step={1}
												defaultValue={activeDays}
												onChange={v => setActiveDays(v)}
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}>
												<SliderMark value={50} {...labelStyles}>
													50
												</SliderMark>
												<SliderMark value={100} {...labelStyles}>
													100
												</SliderMark>
												<SliderMark value={150} {...labelStyles}>
													150
												</SliderMark>
												<SliderMark value={200} {...labelStyles}>
													200
												</SliderMark>
												<SliderMark value={250} {...labelStyles}>
													250
												</SliderMark>
												<SliderMark value={300} {...labelStyles}>
													300
												</SliderMark>
												<SliderMark value={365} {...labelStyles}>
													365
												</SliderMark>

												<SliderTrack>
													<SliderFilledTrack />
												</SliderTrack>
												<Tooltip
													hasArrow
													bg="teal.500"
													color="white"
													placement="top"
													isDrawerOpen={showTooltip}
													label={`${activeDays} Days`}>
													<SliderThumb />
												</Tooltip>
											</Slider>
										</Flex>
									</FormLabel>
									<Flex w={"100%"} direction={"column"} mt="3">
										<Flex w={"100%"} align={"center"}>
											<Flex
												w={"50%"}
												h={"100px"}
												direction={"column"}
												py={1}
												px={5}
												rounded={"md"}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Text>
													Voice Call Count: <b>{voiceCallValue}</b>
												</Text>
												<Slider
													mt={8}
													aria-label="slider-ex-1"
													colorScheme="teal"
													min={1}
													max={50}
													step={1}
													defaultValue={voiceCallValue}
													onChange={v => setVoiceCallValue(v)}
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}>
													<SliderMark value={1} {...labelStylesVoice}>
														1
													</SliderMark>
													<SliderMark value={25} {...labelStylesVoice}>
														25
													</SliderMark>
													<SliderMark value={50} {...labelStylesVoice}>
														50
													</SliderMark>
													<SliderTrack>
														<SliderFilledTrack />
													</SliderTrack>
													<Tooltip
														hasArrow
														bg="teal.500"
														color="white"
														placement="top"
														isDrawerOpen={showTooltip}
														label={`${voiceCallValue}`}>
														<SliderThumb />
													</Tooltip>
												</Slider>
											</Flex>
											<Flex
												ml={1}
												w={"50%"}
												h={"100px"}
												direction={"column"}
												py={1}
												px={5}
												rounded={"md"}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Text>
													Video Call Count: <b>{videoCallValue}</b>
												</Text>
												<Slider
													mt={8}
													aria-label="slider-ex-1"
													colorScheme="teal"
													min={1}
													max={50}
													step={1}
													defaultValue={videoCallValue}
													onChange={v => setVideoCallValue(v)}
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}>
													<SliderMark value={1} {...labelStylesVoice}>
														1
													</SliderMark>
													<SliderMark value={25} {...labelStylesVoice}>
														25
													</SliderMark>
													<SliderMark value={50} {...labelStylesVoice}>
														50
													</SliderMark>
													<SliderTrack>
														<SliderFilledTrack />
													</SliderTrack>
													<Tooltip
														hasArrow
														bg="teal.500"
														color="white"
														placement="top"
														isDrawerOpen={showTooltip}
														label={`${videoCallValue}`}>
														<SliderThumb />
													</Tooltip>
												</Slider>
											</Flex>
										</Flex>
									</Flex>
									<Box mt="3" ml="2">
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Select Source Language(s) (max 10/None means all)
										</Text>
										<Checkbox isChecked={sourceLangDisabled} onChange={handleSetSourceLanguageAll}><Text fontSize="sm" fontWeight="bold" >Select All Languages</Text></Checkbox>
										<Multiselect
											style={{
												option: {
													width: "100%",
													color: blackWhiteColor,
												},
												inputField: {
													color: blackWhiteColor
												},
												optionContainer: {
													background: whiteDarkBg
												}
											}}
											closeOnSelect={false}
											w={"100%"}
											options={languagesList}
											selectedValues={sourceLanguagesData}
											onSelect={selectedList => {
												selectedList?.length > 10 ? handleSourceSelect(selectedList.pop()) :
													handleSourceSelect(selectedList)
											}}
											onRemove={selectedList => handleSourceSelect(selectedList)}
											displayValue="englishName"
											placeholder="Select supported source language(s)"
											disable={sourceLangDisabled}
										/>

									</Box>
									<Box mt="3" ml="2">
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Select Target Language(s) (max 10/None means all)
										</Text>
										<Checkbox isChecked={targetLangDisabled} onChange={handleSetTargetLanguageAll}><Text fontSize="sm" fontWeight="bold">Select All Languages</Text></Checkbox>
										<Multiselect
											style={{
												option: {
													width: "100%",
													color: blackWhiteColor,
												},
												inputField: {
													color: blackWhiteColor
												},
												optionContainer: {
													background: whiteDarkBg
												}
											}}
											closeOnSelect={false}
											w={"100%"}
											options={languagesList}
											selectedValues={targetLanguagesData}
											onSelect={selectedList => {
												selectedList?.length > 10 ? handleTargetSelect(selectedList.pop()) :
													handleTargetSelect(selectedList)
											}}
											onRemove={selectedList => handleTargetSelect(selectedList)}
											displayValue="englishName"
											placeholder="Select supported target language(s)"
											disable={targetLangDisabled}
										/>
									</Box>
									<FormLabel mt="3" ml="2" required>
										<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Call Time
										</Text>
										<Flex
											mt={"10px"}
											h={"100px"}
											w={"100%"}
											rounded={"md"}
											borderWidth={"0.5px"}
											borderColor={"gray.200"}>
											<Slider
												aria-label="slider-ex-1"
												colorScheme="teal"
												min={0}
												max={600}
												step={1}
												defaultValue={callTime}
												onChange={v => {
													setCallTime(v);
												}}
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}>
												<SliderMark value={100} {...labelStyles}>
													100
												</SliderMark>
												<SliderMark value={200} {...labelStyles}>
													200
												</SliderMark>
												<SliderMark value={300} {...labelStyles}>
													300
												</SliderMark>
												<SliderMark value={400} {...labelStyles}>
													400
												</SliderMark>
												<SliderMark value={500} {...labelStyles}>
													500
												</SliderMark>
												<SliderMark value={600} {...labelStyles}>
													600
												</SliderMark>

												<SliderTrack>
													<SliderFilledTrack />
												</SliderTrack>
												<Tooltip
													hasArrow
													bg="teal.500"
													color="white"
													placement="top"
													isDrawerOpen={showTooltip}
													label={`${callTime} Minutes`}>
													<SliderThumb />
												</Tooltip>
											</Slider>
										</Flex>
									</FormLabel>
								</Flex>
							</DrawerBody>
							<DrawerFooter>
								<Flex w={"100%"} direction={"row"}>
									<Button
										variant="outline"
										mr={3}
										onClick={() => {
											closeDrawer();
										}}>
										Cancel
									</Button>
									{!isAddUpdateDisabled
										? <Button
											colorScheme="blue"
											onClick={() => {
												addUpdateCardDetails();
											}}
											isDisabled={saveCardLoader}
											isLoading={saveCardLoader}>
											{cardUuid ? "Update" : "Create New"} Card
										</Button>
										: <Button
											colorScheme="blue"
											isDisabled={true}
											isLoading={saveCardLoader}>
											{cardUuid ? "Update" : "Create New"} Card
										</Button>}
								</Flex>

							</DrawerFooter>
						</DrawerContent>
					)}
				{drawerType === DRAWER.SHARECARD && (<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						Share LingoCard
					</DrawerHeader>
					<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">
						<Box p="3" h="95%">
							<FormLabel mt="3" ml="2" required>
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									To Email Address(es):
								</Text>
								<TagsInput
									maxTags={100}
									style={{ width: '100%', background: "red" }}
									value={toEmails}
									onChange={tags => setToEmails(tags)}
									inputProps={{ placeholder: 'Enter Email' }}
								/>
								<Text
									textAlign={"right"}
									fontWeight={"500"}
									fontSize={"sm"}
									mt="2"
								>
									{toEmails?.length || 0}/100
								</Text>
							</FormLabel>

							<FormLabel mt="3" ml="2" required>
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Message:
								</Text>
								<Textarea
									mb="0"
									me="30px"
									rows="10"
									id="cardShareMessage"
									value={cardShareMessage}
									name="cardShareMessage"
									onChange={(e) => {
										setcardShareMessage(e.target.value);
									}}
									placeholder="Enter message"
									w="100%"
									maxlength={500}
								/>
								<Text
									textAlign={"right"}
									fontWeight={"500"}
									fontSize={"sm"}
									mt="2"
								>
									{cardShareMessage?.length || 0}/500
								</Text>
							</FormLabel>
							<Text>
								Note: An email will be sent to all above emails address with the
								message
							</Text>
						</Box>
					</DrawerBody>
					<DrawerFooter>
						<Flex w={'100%'} mt="4" alignItems={'center'} p="3" borderTopWidth={'1px'} borderColor="grey.200">
							<Button
								bg={blueButtonBg}
								borderRadius={'10px'}
								size="sm"
								mr="2"
								onClick={() => {
									handleShareCard()
								}}
								isDisabled={
									(!toEmails || !cardShareMessage)
								}
								isLoading={saveCardLoader ? true : false}
							>
								Send Email
							</Button>

							<Button variant='outline' borderRadius={'10px'} colorScheme='red' size="sm" onClick={onDrawerClose}>
								Close
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>)}

				{drawerType === DRAWER.SHARESMS && (<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						SMS LingoCard
					</DrawerHeader>
					<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">
						<Box p="3" h="95%">
							<FormControl>
								<FormLabel>Enter Phone number</FormLabel>
								<InputGroup>
									<InputLeftElement width="4rem">
										<Select
											top="2"
											left="2"
											zIndex={1}
											bottom={0}
											opacity={0}
											height="100%"
											position="absolute"
											value={selectedCountryCode}
											onChange={e => setSelectedCountryCode(e.target.value)}
										>
											<option value="" />
											{countryCodes.map(option => (
												<option value={option.code}>{option.code}</option>
											))}
										</Select>
										<Flex pl={2} width="100%" alignItems="center">
											{selectedCountryCode ? (
												<Box mr="6px" width="50%" flex={1}>
													<Text fontSize="bold">{selectedCountryCode}</Text>
												</Box>
											) : (
												<PhoneIcon />
											)
											}
										</Flex>
									</InputLeftElement>
									<Input
										type="tel"
										pl="4rem"
										value={phoneNumber}
										onChange={e => setPhoneNumber(e.target.value)}
										placeholder="Enter Phone Number"
									/>
								</InputGroup>
								{errors?.invalidNumber && <FormHelperText color="red.600" fontSize="sm">Invalid Phone Number</FormHelperText>}
							</FormControl>

							<FormLabel mt="3" ml="2" required>
								<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
									Message:
								</Text>
								<Textarea
									mb="0"
									me="30px"
									rows="10"
									id="cardShareMessage"
									value={cardShareMessage}
									name="cardShareMessage"
									onChange={(e) => {
										setcardShareMessage(e.target.value);
									}}
									placeholder="Enter message"
									w="100%"
									maxlength={500}
								/>
								<Text
									textAlign={"right"}
									fontWeight={"500"}
									fontSize={"sm"}
									mt="2"
								>
									{cardShareMessage?.length || 0}/500
								</Text>
							</FormLabel>
							<Text>
								Note: An SMS will be sent to all above phone number with the
								message
							</Text>
						</Box>
					</DrawerBody>
					<DrawerFooter>
						<Flex w={'100%'} mt="4" alignItems={'center'} p="3" borderTopWidth={'1px'} borderColor="grey.200">
							<Button
								bg={blueButtonBg}
								borderRadius={'10px'}
								size="sm"
								mr="2"
								onClick={() => {
									handleSendSms()
								}}
								isDisabled={
									((!selectedCountryCode && !phoneNumber) || !cardShareMessage)
								}
								isLoading={smsSharingLoading === activeCardDetails.lingo_card_uuid}
							>
								Send SMS
							</Button>

							<Button variant='outline' borderRadius={'10px'} colorScheme='red' size="sm" onClick={onDrawerClose}>
								Close
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>)}

				{drawerType === DRAWER.CARD_DETAILS && (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth={"1px"}>
							LingoCard Details
						</DrawerHeader>
						<DrawerBody overflow="hidden" overflowY={"scroll"} mt="3">

							<Text><strong>Card Access Duration:</strong> {moment(activeCardDetails.card_start_time).format("MMM DD,YYYY,HH:mm")} - {moment(activeCardDetails.card_end_time).format("MMM DD,YYYY,HH:mm")}</Text>
							<Text><strong>Time Left Before Card Expire:</strong> {getDaysHoursMinSec(activeCardDetails.card_start_time, activeCardDetails.card_end_time)}</Text>
							<Text><strong>Card code:</strong>{activeCardDetails?.card_code}</Text>
							{
								commonDetails && (<>
									<Heading as="h6" size="md">Phone Numbers:</Heading>
									<Box pl={6} mt={2}>
										<ul>
											{Object.entries(commonDetails.phone_numbers).map(([key, value]) => (<li>
												<Flex w="100%" gap={2}>
													<Flex w="20%">{key}:</Flex>
													<Flex w="80%"> [{value}] (tel: {value})</Flex>

												</Flex>
											</li>))}
										</ul>
									</Box>
									<br />
									<Heading as="h6" size="md">Language Codes:</Heading>
									<Box pl={6} mt={2}>
										<ul>
											{Object.entries(commonDetails.language__extension_codes).map(([key, value]) => (<li>
												<Flex w="100%" gap={2}>
													<Flex w="5%">{key}.</Flex>
													<Flex w="80%"> {value}</Flex>

												</Flex>
											</li>))}
										</ul>
									</Box>
								</>)
							}

						</DrawerBody>
						<DrawerFooter>
						</DrawerFooter>
					</DrawerContent>
				)}

			</Drawer>
			<Modal isOpen={isModalOpen} onClose={onModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Disable card Forever?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						You will not be able to use this card after disabling!
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onModalClose}>
							Close
						</Button>
						<Button colorScheme='red' onClick={handleCardManage}>Disable Card</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{(totalrecords > 0 && !loading)
				? <Pagination
					currentPage={currPage}
					setCurrentPage={setCurrPage}
					setRecordsPerPage={setRecordsPerPage}
					totalPages={totalPages}
					recordsPerPage={recordsPerPage}
				/>
				: null}
		</Box>
	)
}

export default CardListing